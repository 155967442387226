<template>
  <button
    class="btn bg-white text-blue ms-2 border-start d-none"
    data-bs-toggle="modal"
    :data-bs-target="`#${targetModal}`"
    v-show="false"
    ref="calibrationModalButton"
  >
    Calibracion
  </button>
  <div
    class="modal fade"
    :id="targetModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header align-content-center">
          <div class="px-2" v-show="category === 'Calibracion'">
            <svg
              id="Grupo_1293"
              data-name="Grupo 1293"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="22.343"
              height="20.171"
              viewBox="0 0 22.343 20.171"
              fill="#000"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectángulo_2201"
                    data-name="Rectángulo 2201"
                    width="22.343"
                    height="20.171"
                    fill="#000"
                  />
                </clipPath>
              </defs>
              <g
                id="Grupo_1292"
                data-name="Grupo 1292"
                clip-path="url(#clip-path)"
              >
                <path
                  id="Trazado_2368"
                  data-name="Trazado 2368"
                  d="M22.344,5.72a.578.578,0,0,1-.555.256c-1.069-.008-2.138,0-3.207,0h-.26v.3q0,5.355,0,10.71a3.033,3.033,0,0,1-1.788,2.908,3.555,3.555,0,0,1-1.636.28c-3.941,0-7.882.015-11.823-.01a3.048,3.048,0,0,1-3.067-3C0,16.582,0,16.008,0,15.433c0-.333.132-.464.462-.464q1.222,0,2.443,0h.272c0-.105,0-.2,0-.288q.045-6.173.09-12.346A2.26,2.26,0,0,1,5.223.025,2.682,2.682,0,0,1,5.657,0h14.2a2.361,2.361,0,0,1,2.453,2,.2.2,0,0,0,.033.054ZM3.9,14.968h8.5c.431,0,.537.107.538.542,0,.487,0,.974,0,1.461a2.411,2.411,0,0,0,.956,2.006,2.316,2.316,0,0,0,3.1-.306,2.532,2.532,0,0,0,.609-1.7q-.008-7.208-.005-14.417a2.529,2.529,0,0,1,.378-1.409c.085-.132.18-.258.3-.424H9.905C8.5.721,7.1.724,5.7.72A1.637,1.637,0,0,0,4.3,1.343,1.715,1.715,0,0,0,3.987,2.43q-.044,6.15-.09,12.3c0,.071,0,.142,0,.238M.757,15.7A14.592,14.592,0,0,0,.77,17.471a2.353,2.353,0,0,0,2.419,1.98c3.28-.02,6.559-.006,9.839-.007.07,0,.141-.007.246-.012-.061-.066-.1-.107-.135-.145a2.8,2.8,0,0,1-.873-1.714c-.054-.533-.035-1.074-.048-1.611,0-.084,0-.168,0-.259ZM21.593,5.229c0-1.057.06-2.107-.018-3.146a1.594,1.594,0,0,0-2.028-1.3,1.658,1.658,0,0,0-1.224,1.659c-.006.872,0,1.745,0,2.618a1.647,1.647,0,0,0,.016.165Z"
                  transform="translate(0 0)"
                  fill="#000"
                />
                <path
                  id="Trazado_2369"
                  data-name="Trazado 2369"
                  d="M169.09,56.948a15.089,15.089,0,0,1-.077,1.607,2.565,2.565,0,0,1-1.107,1.757c-.576.4-1.163.795-1.741,1.2a.439.439,0,0,1-.56,0c-.579-.4-1.171-.784-1.741-1.2a2.691,2.691,0,0,1-1.165-2.229c-.016-.813,0-1.627-.006-2.441a.457.457,0,0,1,.29-.46q1.321-.642,2.637-1.3a.544.544,0,0,1,.519,0q1.326.658,2.656,1.307a.44.44,0,0,1,.278.446c-.009.436,0,.872,0,1.308h.021m-5.67.135c0,.32,0,.639,0,.959a2.019,2.019,0,0,0,.908,1.717c.471.327.948.647,1.417.977a.2.2,0,0,0,.267,0c.475-.334.959-.656,1.436-.988a1.993,1.993,0,0,0,.9-1.679c.012-.7,0-1.41.006-2.114a.238.238,0,0,0-.159-.248c-.724-.352-1.443-.715-2.169-1.063a.36.36,0,0,0-.275,0c-.726.348-1.445.711-2.17,1.062a.25.25,0,0,0-.16.269c.007.37,0,.741,0,1.112"
                  transform="translate(-155.319 -51.383)"
                  fill="#000"
                />
                <path
                  id="Trazado_2370"
                  data-name="Trazado 2370"
                  d="M137.629,285.017h-4.121c-.065,0-.131,0-.2,0a.363.363,0,1,1,0-.725,1.512,1.512,0,0,1,.153,0h8.33c.036,0,.073,0,.109,0,.271.012.426.146.426.366s-.156.361-.427.363c-.705,0-1.41,0-2.115,0h-2.159"
                  transform="translate(-126.914 -271.404)"
                  fill="#000"
                />
                <path
                  id="Trazado_2371"
                  data-name="Trazado 2371"
                  d="M137.7,247.486q2.115,0,4.23,0c.281,0,.438.12.445.339a.325.325,0,0,1-.275.362,1.351,1.351,0,0,1-.282.02h-8.264a1.6,1.6,0,0,1-.282-.018.323.323,0,0,1-.285-.334.335.335,0,0,1,.287-.357,1.07,1.07,0,0,1,.239-.012H137.7"
                  transform="translate(-126.959 -236.271)"
                  fill="#000"
                />
                <path
                  id="Trazado_2372"
                  data-name="Trazado 2372"
                  d="M202.39,117.21c.382-.383.734-.737,1.088-1.089a.857.857,0,0,1,.2-.163.352.352,0,0,1,.458.5.946.946,0,0,1-.138.166q-.629.633-1.261,1.263c-.256.254-.429.253-.685,0q-.285-.284-.566-.573c-.21-.216-.233-.429-.066-.589s.374-.129.589.087c.123.123.239.252.38.4"
                  transform="translate(-192.189 -110.663)"
                  fill="#000"
                />
              </g>
            </svg>
          </div>
          <div class="px-2" v-show="category === 'Historial'">
            <svg
              id="Grupo_1297"
              data-name="Grupo 1297"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              width="21.797"
              height="20.033"
              viewBox="0 0 21.797 20.033"
              fill="#000"
            >
              <defs>
                <clipPath id="clip-path">
                  <rect
                    id="Rectángulo_2202"
                    data-name="Rectángulo 2202"
                    width="21.797"
                    height="20.033"
                    fill="#000"
                  />
                </clipPath>
              </defs>
              <g
                id="Grupo_1296"
                data-name="Grupo 1296"
                clip-path="url(#clip-path)"
              >
                <path
                  id="Trazado_2376"
                  data-name="Trazado 2376"
                  d="M3.17,9.756c.43,0,.792,0,1.155,0a.77.77,0,0,1,.706,1.2q-.87,1.532-1.762,3.051a.77.77,0,0,1-1.38,0Q1,12.484.126,10.952a.771.771,0,0,1,.712-1.2c.266,0,.532,0,.779,0,.1-.624.148-1.231.286-1.819A9.645,9.645,0,0,1,7.572.962,9.692,9.692,0,0,1,18.079,2.315a9.6,9.6,0,0,1,3.628,6.446,9.992,9.992,0,0,1-8.159,11.091A9.824,9.824,0,0,1,4.92,17.376a.78.78,0,1,1,1.04-1.155,8.514,8.514,0,0,0,13.462-2.629A8.515,8.515,0,1,0,3.415,8.1c-.113.534-.162,1.081-.246,1.656"
                  transform="translate(0 0)"
                  fill="#000"
                />
                <path
                  id="Trazado_2377"
                  data-name="Trazado 2377"
                  d="M155.728,49.657q0-1.666,0-3.333c0-.588.291-.945.772-.948s.785.357.785.961c0,2.068,0,4.135,0,6.2a.455.455,0,0,0,.2.4c1.112.88,2.216,1.771,3.327,2.655a.816.816,0,0,1,.362.774.77.77,0,0,1-1.279.48c-.632-.494-1.255-1-1.882-1.5s-1.248-1.009-1.882-1.5a.967.967,0,0,1-.4-.836c.011-1.119,0-2.238,0-3.357"
                  transform="translate(-145.604 -42.428)"
                  fill="#000"
                />
              </g>
            </svg>
          </div>
          <div>
            <span class="fw-bolder fs-3">{{ category }}</span>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div v-show="category === 'Calibracion'">
            <div class="row">
              <div class="col-md-10 col-8 d-flex justify-content-center">
                <span class="fs-1 fw-bolder">Certificación de sensores </span>
              </div>
              <div
                class="col-md-2 col-4 d-flex align-items-center cursor-pointer"
                @click="openHistorial()"
              >
                <img
                  alt="Logo"
                  src="/media/icons/histo-icon.svg"
                  style="width: 20px; height: 21px"
                />
                <span class="text-blue fs-3 ps-2 fw-bolder">Historial</span>
              </div>
            </div>
            <div class="row pt-10" v-if="tipoForm === 'TAMB'">
              <div class="col-12">
                <el-form
                  ref="temInternaForm"
                  :model="dataTempInt"
                  :rules="formRules"
                  class="demo-ruleForm"
                >
                  <div class="card">
                    <div class="card-body">
                      <div class="row mb-4">
                        <div class="col-md-9 col-7 d-flex align-items-center">
                          <i class="bi bi-cpu fs-1"></i>
                          <span class="fs-1 fw-bolder px-2">
                            {{ "TAMB" }}
                          </span>
                          <i class="bi bi-dot fs-2"></i>
                          <span class="text-muted">
                            {{ dataTempInt.nombreTitulo }}
                          </span>
                        </div>
                        <div class="col-md-2 col-4 text-end">
                          <span class="text-muted fs-7">{{
                            dataTempInt.pdfCertificado
                          }}</span>
                          <el-form-item prop="pdfCertificado">
                            <el-input
                              class="d-none"
                              disabled
                              v-model="dataTempInt.pdfCertificado"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-1 d-flex justify-content-center">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="Cargar Pdf"
                            placement="top"
                          >
                            <i
                              class="bi bi-upload text-blue fs-2 cursor-pointer"
                              @click="cargarPdf('TAMB')"
                            ></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="row mb-7">
                        <div class="col-md-7 col-sm-12">
                          <el-form-item prop="nombre">
                            <el-input
                              v-model="dataTempInt.nombre"
                              placeholder="Ingrese el nombre del certificado"
                            />
                          </el-form-item>
                        </div>
                        <div class="col-md-5 col-sm-12 pt-md-0 pt-3">
                          <el-form-item prop="empresaCertificadoraId">
                            <el-select
                              v-model="dataTempInt.empresaCertificadoraId"
                              class="w-100"
                              placeholder="Seleccione la empresa que certifico el sensor"
                            >
                              <el-option
                                v-for="item in empresasCertf"
                                :key="item._id"
                                :label="item.nombre"
                                :value="item._id"
                              />
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-md-6 col-12 d-flex justify-content-start align-items-center"
                        >
                          <el-form-item label="Fecha Inicio" prop="fechaInicio">
                            <el-date-picker
                              v-model="dataTempInt.fechaInicio"
                              type="date"
                              placeholder="Seleccione una fecha"
                              class="w-75"
                            />
                          </el-form-item>
                        </div>
                        <div
                          class="col-md-6 col-12 pt-md-0 pt-5 d-flex justify-content-center align-items-center"
                        >
                          <el-form-item label="Fecha Fin" prop="fechaFinal">
                            <el-date-picker
                              v-model="dataTempInt.fechaFinal"
                              type="date"
                              placeholder="Seleccione una fecha"
                              :disabled-date="disabledDate"
                              class="w-75"
                            />
                          </el-form-item>
                        </div>
                      </div>
                      <!-- campos para registrar calibraciones-->
                      <div class="row pt-5">
                        <div class="table-responsive">
                          <table class="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th>
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                  >
                                    <span class="fw-bolder">Indicación</span>
                                  </div>
                                </th>
                                <th>
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                  >
                                    <span class="fw-bolder">Calibración</span>
                                  </div>
                                </th>
                                <th>
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                  >
                                    <el-tooltip
                                      class="box-item"
                                      effect="dark"
                                      content="Agregar"
                                      placement="top"
                                    >
                                      <i
                                        class="bi bi-plus-circle-dotted text-blue fs-1 cursor-pointer"
                                        @click="agregarCalibracion('TAMB')"
                                      ></i>
                                    </el-tooltip>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr
                                v-for="(
                                  cali, index
                                ) in dataTempInt.calibraciones"
                                :key="index"
                                class="py-3"
                              >
                                <td>
                                  <div
                                    class="d-flex justify-content-center align-items-center pb-3"
                                  >
                                    <el-form-item
                                      :prop="`calibraciones[${index}].indicacion`"
                                      :rules="dynamicRules(index, 'TAMB')"
                                    >
                                      <el-input-number
                                        v-model="cali.indicacion"
                                        :precision="2"
                                        :step="0.1"
                                      />
                                    </el-form-item>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    class="d-flex justify-content-center align-items-center pb-3"
                                  >
                                    <el-form-item
                                      :prop="`calibraciones[${index}].valor`"
                                      :rules="{
                                        required: true,
                                        message: 'El valor es requerido',
                                        trigger: 'blur',
                                      }"
                                    >
                                      <el-input-number
                                        v-model="cali.valor"
                                        :precision="2"
                                        :step="0.1"
                                      />
                                    </el-form-item>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="index > 0">
                                    <el-tooltip
                                      class="box-item"
                                      effect="dark"
                                      content="Eliminar"
                                      placement="top"
                                    >
                                      <i
                                        class="bi bi-trash text-danger fs-1"
                                        @click="
                                          eliminarCalibracion('TAMB', index)
                                        "
                                      ></i>
                                    </el-tooltip>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex justify-content-end">
                          <div class="me-7">
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              content="Guardar"
                              placement="top"
                            >
                              <i
                                class="bi bi-check2-circle fs-1 text-blue cursor-pointer"
                                @click="submitForm('TAMB')"
                              ></i>
                            </el-tooltip>
                          </div>
                          <div v-if="dataTempInt.isEditing">
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              content="Cancelar"
                              placement="top"
                            >
                              <i
                                class="bi bi-x-circle fs-2 text-danger cursor-pointer"
                                @click="resetForm('TAMB')"
                              ></i>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
            <div class="row pt-10" v-if="tipoForm === 'TLD'">
              <div class="col-12">
                <el-form
                  ref="temExternaForm"
                  :model="dataTempExt"
                  :rules="formRules"
                  class="demo-ruleForm"
                >
                  <div class="card">
                    <div class="card-body">
                      <div class="row mb-4">
                        <div class="col-md-9 col-7 d-flex align-items-center">
                          <i class="bi bi-cpu fs-1"></i>
                          <span class="fs-1 fw-bolder px-2">
                            {{ "TLD" }}
                          </span>
                          <i class="bi bi-dot fs-2"></i>
                          <span class="text-muted">
                            {{ dataTempExt.nombreTitulo }}
                          </span>
                        </div>
                        <div class="col-md-2 col-4 text-end">
                          <span class="text-muted fs-7">{{
                            dataTempExt.pdfCertificado
                          }}</span>
                          <el-form-item prop="pdfCertificado">
                            <el-input
                              class="d-none"
                              disabled
                              v-model="dataTempExt.pdfCertificado"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-1 d-flex justify-content-center">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="Cargar Pdf"
                            placement="top"
                          >
                            <i
                              class="bi bi-upload text-blue fs-2 cursor-pointer"
                              @click="cargarPdf('TLD')"
                            ></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="row mb-7">
                        <div class="col-md-7 col-sm-12">
                          <el-form-item prop="nombre">
                            <el-input
                              v-model="dataTempExt.nombre"
                              placeholder="Ingrese el nombre del certificado"
                            />
                          </el-form-item>
                        </div>
                        <div class="col-md-5 col-sm-12 pt-md-0 pt-3">
                          <el-form-item prop="empresaCertificadoraId">
                            <el-select
                              v-model="dataTempExt.empresaCertificadoraId"
                              class="w-100"
                              placeholder="Seleccione la empresa que certifico el sensor"
                            >
                              <el-option
                                v-for="item in empresasCertf"
                                :key="item._id"
                                :label="item.nombre"
                                :value="item._id"
                              />
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-md-6 col-12 d-flex justify-content-start align-items-center"
                        >
                          <el-form-item label="Fecha Inicio" prop="fechaInicio">
                            <el-date-picker
                              v-model="dataTempExt.fechaInicio"
                              type="date"
                              placeholder="Seleccione una fecha"
                              class="w-75"
                            />
                          </el-form-item>
                        </div>
                        <div
                          class="col-md-6 col-12 pt-md-0 pt-5 d-flex justify-content-center align-items-center"
                        >
                          <el-form-item label="Fecha Fin" prop="fechaFinal">
                            <el-date-picker
                              v-model="dataTempExt.fechaFinal"
                              type="date"
                              placeholder="Seleccione una fecha"
                              :disabled-date="disabledDate"
                              class="w-75"
                            />
                          </el-form-item>
                        </div>
                      </div>
                      <!-- campos para registrar calibraciones-->
                      <div class="row pt-5">
                        <div class="table-responsive">
                          <table class="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th>
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                  >
                                    <span class="fw-bolder">Indicación</span>
                                  </div>
                                </th>
                                <th>
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                  >
                                    <span class="fw-bolder">Calibración</span>
                                  </div>
                                </th>
                                <th>
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                  >
                                    <el-tooltip
                                      class="box-item"
                                      effect="dark"
                                      content="Agregar"
                                      placement="top"
                                    >
                                      <i
                                        class="bi bi-plus-circle-dotted text-blue fs-1 cursor-pointer"
                                        @click="agregarCalibracion('TLD')"
                                      ></i>
                                    </el-tooltip>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr
                                v-for="(
                                  cali, index
                                ) in dataTempExt.calibraciones"
                                :key="index"
                                class="py-3"
                              >
                                <td>
                                  <div
                                    class="d-flex justify-content-center align-items-center pb-3"
                                  >
                                    <el-form-item
                                      :prop="`calibraciones[${index}].indicacion`"
                                      :rules="dynamicRules(index, 'TLD')"
                                    >
                                      <el-input-number
                                        v-model="cali.indicacion"
                                        :precision="2"
                                        :step="0.1"
                                      />
                                    </el-form-item>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    class="d-flex justify-content-center align-items-center pb-3"
                                  >
                                    <el-form-item
                                      :prop="`calibraciones[${index}].valor`"
                                      :rules="{
                                        required: true,
                                        message: 'El valor es requerido',
                                        trigger: 'blur',
                                      }"
                                    >
                                      <el-input-number
                                        v-model="cali.valor"
                                        :precision="2"
                                        :step="0.1"
                                      />
                                    </el-form-item>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="index > 0">
                                    <el-tooltip
                                      class="box-item"
                                      effect="dark"
                                      content="Eliminar"
                                      placement="top"
                                    >
                                      <i
                                        class="bi bi-trash text-danger fs-1"
                                        @click="
                                          eliminarCalibracion('TLD', index)
                                        "
                                      ></i>
                                    </el-tooltip>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex justify-content-end">
                          <div class="me-7">
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              content="Guardar"
                              placement="top"
                            >
                              <i
                                class="bi bi-check2-circle fs-1 text-blue cursor-pointer"
                                @click="submitForm('TLD')"
                              ></i>
                            </el-tooltip>
                          </div>
                          <div v-if="dataTempExt.isEditing">
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              content="Cancelar"
                              placement="top"
                            >
                              <i
                                class="bi bi-x-circle fs-2 text-danger cursor-pointer"
                                @click="resetForm('TLD')"
                              ></i>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
            <div class="row pt-10" v-if="tipoForm === 'HR'">
              <div class="col-12">
                <el-form
                  ref="humedadForm"
                  :model="dataHumedad"
                  :rules="formRules"
                  class="demo-ruleForm"
                >
                  <div class="card">
                    <div class="card-body">
                      <div class="row mb-4">
                        <div class="col-md-9 col-7 d-flex align-items-center">
                          <i class="bi bi-cpu fs-1"></i>
                          <span class="fs-1 fw-bolder px-2">
                            {{ "HR" }}
                          </span>
                          <i class="bi bi-dot fs-2"></i>
                          <span class="text-muted">
                            {{ dataHumedad.nombreTitulo }}
                          </span>
                        </div>
                        <div class="col-md-2 col-4 text-end">
                          <span class="text-muted fs-7">{{
                            dataHumedad.pdfCertificado
                          }}</span>
                          <el-form-item prop="pdfCertificado">
                            <el-input
                              class="d-none"
                              disabled
                              v-model="dataHumedad.pdfCertificado"
                            ></el-input>
                          </el-form-item>
                        </div>
                        <div class="col-1 d-flex justify-content-center">
                          <el-tooltip
                            class="box-item"
                            effect="dark"
                            content="Cargar Pdf"
                            placement="top"
                          >
                            <i
                              class="bi bi-upload text-blue fs-2 cursor-pointer"
                              @click="cargarPdf('HR')"
                            ></i>
                          </el-tooltip>
                        </div>
                      </div>
                      <div class="row mb-7">
                        <div class="col-md-7 col-sm-12">
                          <el-form-item prop="nombre" class="w-100">
                            <el-input
                              v-model="dataHumedad.nombre"
                              placeholder="Ingrese el nombre del certificado"
                            />
                          </el-form-item>
                        </div>
                        <div class="col-md-5 col-sm-12 pt-md-0 pt-3">
                          <el-form-item prop="empresaCertificadoraId">
                            <el-select
                              v-model="dataHumedad.empresaCertificadoraId"
                              class="w-100"
                              placeholder="Seleccione la empresa que certifico el sensor"
                            >
                              <el-option
                                v-for="item in empresasCertf"
                                :key="item._id"
                                :label="item.nombre"
                                :value="item._id"
                              />
                            </el-select>
                          </el-form-item>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="col-md-6 col-12 d-flex justify-content-start align-items-center"
                        >
                          <el-form-item label="Fecha Inicio" prop="fechaInicio">
                            <el-date-picker
                              v-model="dataHumedad.fechaInicio"
                              type="date"
                              placeholder="Seleccione una fecha"
                              class="w-75"
                            />
                          </el-form-item>
                        </div>
                        <div
                          class="col-md-6 col-12 pt-md-0 pt-5 d-flex justify-content-center align-items-center"
                        >
                          <el-form-item label="Fecha Fin" prop="fechaFinal">
                            <el-date-picker
                              v-model="dataHumedad.fechaFinal"
                              type="date"
                              placeholder="Seleccione una fecha"
                              :disabled-date="disabledDate"
                              class="w-75"
                            />
                          </el-form-item>
                        </div>
                      </div>
                      <!-- campos para registrar calibraciones-->
                      <div class="row pt-5">
                        <div class="table-responsive">
                          <table class="table table-hover table-bordered">
                            <thead>
                              <tr>
                                <th>
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                  >
                                    <span class="fw-bolder">Indicación</span>
                                  </div>
                                </th>
                                <th>
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                  >
                                    <span class="fw-bolder">Calibración</span>
                                  </div>
                                </th>
                                <th>
                                  <div
                                    class="d-flex justify-content-center align-items-center"
                                  >
                                    <el-tooltip
                                      class="box-item"
                                      effect="dark"
                                      content="Agregar"
                                      placement="top"
                                    >
                                      <i
                                        class="bi bi-plus-circle-dotted text-blue fs-1 cursor-pointer"
                                        @click="agregarCalibracion('HR')"
                                      ></i>
                                    </el-tooltip>
                                  </div>
                                </th>
                              </tr>
                            </thead>
                            <tbody class="table-group-divider">
                              <tr
                                v-for="(
                                  cali, index
                                ) in dataHumedad.calibraciones"
                                :key="index"
                                class="py-3"
                              >
                                <td>
                                  <div
                                    class="d-flex justify-content-center align-items-center pb-3"
                                  >
                                    <el-form-item
                                      :prop="`calibraciones[${index}].indicacion`"
                                      :rules="dynamicRules(index, 'HR')"
                                    >
                                      <el-input-number
                                        v-model="cali.indicacion"
                                        :precision="2"
                                        :step="0.1"
                                        :min="0"
                                      />
                                    </el-form-item>
                                  </div>
                                </td>
                                <td>
                                  <div
                                    class="d-flex justify-content-center align-items-center pb-3"
                                  >
                                    <el-form-item
                                      :prop="`calibraciones[${index}].valor`"
                                      :rules="{
                                        required: true,
                                        message: 'El valor es requerido',
                                        trigger: 'blur',
                                      }"
                                    >
                                      <el-input-number
                                        v-model="cali.valor"
                                        :precision="2"
                                        :step="0.1"
                                      />
                                    </el-form-item>
                                  </div>
                                </td>
                                <td>
                                  <div v-if="index > 0">
                                    <el-tooltip
                                      class="box-item"
                                      effect="dark"
                                      content="Eliminar"
                                      placement="top"
                                    >
                                      <i
                                        class="bi bi-trash text-danger fs-1"
                                        @click="
                                          eliminarCalibracion('HR', index)
                                        "
                                      ></i>
                                    </el-tooltip>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 d-flex justify-content-end">
                          <div class="me-7">
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              content="Guardar"
                              placement="top"
                            >
                              <i
                                class="bi bi-check2-circle fs-1 text-blue cursor-pointer"
                                @click="submitForm('HR')"
                              ></i>
                            </el-tooltip>
                          </div>
                          <div v-if="dataHumedad.isEditing">
                            <el-tooltip
                              class="box-item"
                              effect="dark"
                              content="Cancelar"
                              placement="top"
                            >
                              <i
                                class="bi bi-x-circle fs-2 text-danger cursor-pointer"
                                @click="resetForm('HR')"
                              ></i>
                            </el-tooltip>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-form>
              </div>
            </div>
          </div>
          <div v-show="category === 'Historial'">
            <div class="row">
              <div
                class="col-1 d-flex align-items-center cursor-pointer"
                @click="category = 'Calibracion'"
              >
                <i class="bi bi-chevron-left text-blue fs-2"></i>
                <span class="text-blue fs-3 ps-2 fw-bolder">Atras</span>
              </div>
              <div class="col-md-10 col-11 d-flex justify-content-center">
                <span class="fs-1 fw-bolder">Todas las certificaciones</span>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-12 d-flex justify-content-center">
                <span class="fs-5">Filtrar por fecha</span>
              </div>
            </div>
            <div class="row pt-2">
              <div class="col-lg-6 col-sm-12 col-sm-12">
                <el-date-picker
                  v-model="filters[0]"
                  type="date"
                  class="w-100"
                  placeholder="Ingrese una fecha inicial"
                />
              </div>
              <div class="col-lg-6 col-sm-12 col-sm-12 pt-lg-0 pt-sm-5 pt-5">
                <el-date-picker
                  v-model="filters[1]"
                  type="date"
                  class="w-100"
                  :disabled="filters[0] === null"
                  :disabled-date="disableDateFilters"
                  placeholder="Ingrese una fecha final"
                />
              </div>
            </div>
            <div class="row pt-5">
              <div class="col-12">
                <el-table
                  row-key="_id"
                  :data="tableData.data"
                  style="width: 100%"
                  header-row-class-name="text-dark fw-bolder"
                  @sort-change="sortChange"
                >
                  <el-table-column type="expand">
                    <template #default="props">
                      <el-table
                        :data="props.row.calibraciones"
                        header-row-class-name="text-dark fw-bolder"
                      >
                        <el-table-column
                          prop="indicacion"
                          label="Indicación"
                          align="center"
                        ></el-table-column>
                        <el-table-column
                          prop="valor"
                          label="Calibración"
                          align="center"
                        ></el-table-column>
                      </el-table>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="tipo"
                    label="Sensor"
                    width="180"
                    align="center"
                  />
                  <el-table-column label="Certificador" width="180">
                    <template #default="props">
                      <div>{{ props.row.empresaCertificadora[0].nombre }}</div>
                    </template>
                  </el-table-column>
                  <el-table-column
                    prop="fechaInicio"
                    label="Inicio"
                    sortable
                    align="center"
                  />
                  <el-table-column
                    prop="fechaFinal"
                    label="Fin"
                    sortable
                    align="center"
                  />
                  <el-table-column label="PDF" width="120" align="center">
                    <template #default="props">
                      <div style="display: flex; align-items: center">
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          content="Editar"
                          placement="top"
                        >
                          <i
                            class="bi bi-pencil fs-1 text-blue"
                            @click="editarCalibracion(props.row._id)"
                          ></i>
                        </el-tooltip>
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          content="Ver Pdf"
                          placement="top"
                        >
                          <i
                            class="bi bi-eye fs-1 ms-5 text-blue"
                            @click="verPdf(props.row._id)"
                          ></i>
                        </el-tooltip>
                        <!--                        <el-tooltip-->
                        <!--                          class="box-item"-->
                        <!--                          effect="dark"-->
                        <!--                          content="Eliminar"-->
                        <!--                          placement="top"-->
                        <!--                        >-->
                        <!--                          <i class="bi bi-trash fs-1 ms-5 text-danger"></i>-->
                        <!--                        </el-tooltip>-->
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
            <div class="row pt-5">
              <div class="col-12 d-flex justify-content-end">
                <el-pagination
                  v-model:currentPage="paginate.page"
                  :small="true"
                  :page-size="paginate.pageSize"
                  :pager-count="5"
                  :background="true"
                  :page-count="paginate.last_page"
                  layout="prev, pager, next , slot"
                  :total="paginate.total"
                >
                  <template #default>
                    <span class="text-muted fs-6 texto-paginator">
                      Mostrando del
                      {{ paginate.pageSize * (paginate.page - 1) + 1 }} al
                      {{
                        paginate.total > paginate.rowsPerPage
                          ? paginate.rowsPerPage
                          : paginate.total
                      }}
                      <!--                      de un Total: {{ tableData.length }}-->
                    </span>
                  </template>
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <input
      type="file"
      class="d-none"
      ref="pdfTamb"
      accept="application/pdf"
      @change="uploadPDf('TAMB')"
    />
    <input
      type="file"
      class="d-none"
      ref="pdfTld"
      accept="application/pdf"
      @change="uploadPDf('TLD')"
    />
    <input
      type="file"
      class="d-none"
      ref="pdfHm"
      accept="application/pdf"
      @change="uploadPDf('HR')"
    />
  </div>
</template>

<script>
import Swal from "sweetalert2";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { computed, onMounted, ref, watch, reactive } from "vue";

import {
  getEmpresasCertificadoras,
  verSensoresByDispositivo,
  crearCalibracion,
  getHistorialCalibracion,
  getOneCalibracion,
  updateCalibracion,
} from "@/services/calibraciones.service";
import axios from "axios";

dayjs.locale("es");

export default {
  name: "CalibrationModal",
  props: {
    datosDispo: {
      type: Object,
      default: () => ({}),
    },
    targetModal: {
      type: String,
      default: "calibrationModal",
    },
  },
  setup(props) {
    const calibrationModalButton = ref();
    const category = ref("Calibracion");
    const pdfTld = ref();
    const pdfHm = ref();
    const pdfTamb = ref();
    const tipoForm = ref("");
    const empresasCertf = ref([]);
    const infoDispositivo = ref({});
    const dataTempInt = ref({
      nombreTitulo: "Temperatura Interna",
      tipoSensor: "",
      sensorId: "",
      nombre:
        "Certificado de calibración del monitoreo de la temperatura interna",
      fechaInicio: dayjs(dayjs().format("YYYY-MM-DD")).$d,
      fechaFinal: dayjs(dayjs().format("YYYY-MM-DD")).$d,
      isEditing: false,
      calibraciones: [{ indicacion: 0, valor: 0 }],
      empresaCertificadoraId: "",
      pdfCertificado: "",
      id: null, // solo para ediicion
    });
    const dataTempExt = ref({
      nombreTitulo: "Temperatura Externa",
      tipoSensor: "",
      sensorId: "",
      nombre: "Certificado de calibración del monitoreo ambiental",
      fechaInicio: dayjs(dayjs().format("YYYY-MM-DD")).$d,
      fechaFinal: dayjs(dayjs().format("YYYY-MM-DD")).$d,
      isEditing: false,
      calibraciones: [{ indicacion: 0, valor: 0 }],
      empresaCertificadoraId: "",
      pdfCertificado: "",
      id: null, // solo para ediicion
    });
    const dataHumedad = ref({
      nombreTitulo: "Humedad",
      tipoSensor: "",
      sensorId: "",
      nombre: "Certificado de calibración del monitoreo de la humedad relativa",
      fechaInicio: dayjs(dayjs().format("YYYY-MM-DD")).$d,
      fechaFinal: dayjs(dayjs().format("YYYY-MM-DD")).$d,
      isEditing: false,
      calibraciones: [{ indicacion: 0, valor: 0 }],
      empresaCertificadoraId: "",
      pdfCertificado: "",
      id: null, // solo para ediicion
    });

    /*variables data table historial*/
    const paginate = ref({
      page: 1,
      rowsPerPage: 10,
      total: 0,
      pageSize: 10,
      last_page: 1,
    });
    const filters = ref([null, null]);
    const orden = ref({
      column: null,
      dir: null,
    });
    const tableData = ref([]);

    /* refs de formularios */
    const temInternaForm = ref();
    const temExternaForm = ref();
    const humedadForm = ref();

    /*reglas para todos los formularios*/
    const formRules = reactive({
      nombre: [
        {
          required: true,
          message: "El nombre del certificado es requerido",
          trigger: "blur",
        },
      ],
      empresaCertificadoraId: [
        {
          required: true,
          message: "La empresa certificadora es requerida",
          trigger: "blur",
        },
      ],
      fechaInicio: [
        {
          required: true,
          message: "La fecha de inicio es requerida",
          trigger: "blur",
        },
      ],
      fechaFinal: [
        {
          required: true,
          message: "La fecha de finalización es requerida",
          trigger: "blur",
        },
      ],
      pdfCertificado: [
        {
          required: true,
          message: "Por favor adjunta el certificado",
          trigger: "blur",
        },
      ],
    });

    const toggle = async (tipo) => {
      tipoForm.value = tipo;
      category.value = "Calibracion";
      await fetchHistorial();
      if (tableData.value.data.length > 0) {
        let id = tableData.value.data[0]._id;
        editarCalibracion(id);
      }
      calibrationModalButton.value.click();
    };

    const cargarPdf = (sensor) => {
      switch (sensor) {
        case "TLD":
          if (pdfTld.value.files.length > 0) {
            const input = pdfTld.value;
            input.type = "text";
            input.type = "file";
            dataTempExt.value.pdfCertificado = "";
          }
          pdfTld.value.click();
          break;
        case "HR":
          if (pdfHm.value.files.length > 0) {
            const input = pdfHm.value;
            input.type = "text";
            input.type = "file";
            dataHumedad.value.pdfCertificado = "";
          }
          pdfHm.value.click();
          break;
        case "TAMB":
          if (pdfTamb.value.files.length > 0) {
            const input = pdfTamb.value;
            input.type = "text";
            input.type = "file";
            dataTempInt.value.pdfCertificado = "";
          }
          pdfTamb.value.click();
          break;
        default:
          break;
      }
    };
    /*validación de archivo subido*/
    const uploadPDf = (sensor) => {
      const alerta = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      if (sensor === "TAMB") {
        if (pdfTamb.value.files.length > 0) {
          let error = 0;
          const input = pdfTamb.value;
          if (pdfTamb.value.files[0].type !== "application/pdf") {
            input.type = "text";
            input.type = "file";
            dataTempInt.value.pdfCertificado = "";
            error += 1;
            alerta.fire({
              icon: "error",
              title: "El archivo no es un PDF",
            });
            return;
          }
          if (pdfTamb.value.files[0].size > 5000000) {
            input.type = "text";
            input.type = "file";
            dataTempInt.value.pdfCertificado = "";
            error += 1;
            alerta.fire({
              icon: "error",
              title: "El peso del archivo no puede ser mayor a 5MB",
            });
            return;
          }
          if (error === 0) {
            alerta.fire({
              icon: "success",
              title: "Archivo cargado correctamente",
            });
            dataTempInt.value.pdfCertificado = pdfTamb.value.files[0].name;
          }
        }
      } else if (sensor === "TLD") {
        if (pdfTld.value.files.length > 0) {
          let error = 0;
          const input = pdfTld.value;
          if (pdfTld.value.files[0].type !== "application/pdf") {
            input.type = "text";
            input.type = "file";
            dataTempExt.value.pdfCertificado = "";
            error += 1;
            alerta.fire({
              icon: "error",
              title: "El archivo no es un PDF",
            });
            return;
          }
          if (pdfTld.value.files[0].size > 5000000) {
            input.type = "text";
            input.type = "file";
            dataTempExt.value.pdfCertificado = "";
            error += 1;
            alerta.fire({
              icon: "error",
              title: "El peso del archivo no puede ser mayor a 5MB",
            });
            return;
          }
          if (error === 0) {
            alerta.fire({
              icon: "success",
              title: "Archivo cargado correctamente",
            });
            dataTempExt.value.pdfCertificado = pdfTld.value.files[0].name;
          }
        }
      } else if (sensor === "HR") {
        if (pdfHm.value.files.length > 0) {
          let error = 0;
          const input = pdfHm.value;
          if (pdfHm.value.files[0].type !== "application/pdf") {
            input.type = "text";
            input.type = "file";
            dataHumedad.value.pdfCertificado = "";
            error += 1;
            alerta.fire({
              icon: "error",
              title: "El archivo no es un PDF",
            });
            return;
          }
          if (pdfHm.value.files[0].size > 5000000) {
            input.type = "text";
            input.type = "file";
            dataHumedad.value.pdfCertificado = "";
            error += 1;

            alerta.fire({
              icon: "error",
              title: "El peso del archivo no puede ser mayor a 5MB",
            });
            return;
          }
          if (error === 0) {
            alerta.fire({
              icon: "success",
              title: "Archivo cargado correctamente",
            });
            dataHumedad.value.pdfCertificado = pdfHm.value.files[0].name;
          }
        }
      }
    };
    /**
     * @description Obtener los sensores por dispositivo
     * @param dispositivoId
     */
    const fetchSensoresByDispositivo = async (dispositivoId) => {
      verSensoresByDispositivo(dispositivoId)
        .then((res) => {
          infoDispositivo.value = res.data;
          let infoTInterna = infoDispositivo.value.filter(
            (item) => item.tipo === 1
          )[0];
          let infoTExterna = infoDispositivo.value.filter(
            (item) => item.tipo === 2
          )[0];
          let infoHumedad = infoDispositivo.value.filter(
            (item) => item.tipo === 3
          )[0];

          dataTempInt.value.nombreTitulo = infoTInterna.nombre;
          dataTempInt.value.tipoSensor = infoTInterna.tipo;
          dataTempInt.value.sensorId = infoTInterna._id;

          dataTempExt.value.nombreTitulo = infoTExterna.nombre;
          dataTempExt.value.tipoSensor = infoTExterna.tipo;
          dataTempExt.value.sensorId = infoTExterna._id;

          dataHumedad.value.nombreTitulo = infoHumedad.nombre;
          dataHumedad.value.tipoSensor = infoHumedad.tipo;
          dataHumedad.value.sensorId = infoHumedad._id;
        })
        .catch((err) => {
          console.error(err.message);
        });
    };
    /* validación y envio de datos de calibración*/
    const submitForm = async (tipo) => {
      if (tipo === "TAMB") {
        await temInternaForm.value.validate((valid, fields) => {
          if (valid) {
            Swal.fire({
              title: dataTempInt.value.isEditing
                ? "¿ Desea guardar los cambios realizados ? "
                : "¿ Desea crear la calibración ? ",
              icon: "question",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: dataTempInt.value.isEditing
                ? "Actualizar"
                : "Crear",
              denyButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                const formData = new FormData();
                formData.append("sensorId", dataTempInt.value.sensorId);
                formData.append("nombre", dataTempInt.value.nombre);
                formData.append(
                  "fechaInicio",
                  dayjs(dataTempInt.value.fechaInicio).format("YYYY-MM-DD")
                );
                formData.append(
                  "fechaFinal",
                  dayjs(dataTempInt.value.fechaFinal).format("YYYY-MM-DD")
                );
                formData.append(
                  "calibraciones",
                  JSON.stringify(dataTempInt.value.calibraciones)
                );
                formData.append(
                  "empresaCertificadoraId",
                  dataTempInt.value.empresaCertificadoraId
                );
                formData.append("pdfCertificado", pdfTamb.value.files[0]);
                if (dataTempInt.value.isEditing) {
                  const id = dataTempInt.value.id;
                  if (id) {
                    updateCalibracion(id, formData)
                      .then((res) => {
                        if (res.status === 200) {
                          const input = pdfTamb.value;
                          input.type = "text";
                          input.type = "file";
                          resetForm("TAMB");
                          Swal.fire({
                            icon: "success",
                            title: res.data.message,
                          });
                        }
                      })
                      .catch(({ response }) => {
                        Swal.fire({
                          icon: "error",
                          title: response.data.message,
                        });
                      });
                  }
                } else {
                  crearCalibracion(formData)
                    .then((res) => {
                      temInternaForm.value.resetFields();
                      const input = pdfTamb.value;
                      input.type = "text";
                      input.type = "file";
                      dataTempInt.value.calibraciones = [
                        { indicacion: 0, valor: 0 },
                      ];

                      Swal.fire({
                        icon: "success",
                        title: res.data.message,
                      });
                    })
                    .catch(({ response }) => {
                      console.error(response, "error");
                      Swal.fire({
                        icon: "error",
                        title: response.data.message,
                      });
                    });
                }
              }
            });
          } else {
            console.error("error submit!", fields);
          }
        });
      } else if (tipo === "TLD") {
        await temExternaForm.value.validate((valid, fields) => {
          if (valid) {
            Swal.fire({
              title: dataTempExt.value.isEditing
                ? "¿ Desea guardar los cambios realizados ? "
                : "¿ Desea crear la calibración ? ",
              icon: "question",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: dataTempExt.value.isEditing
                ? "Actualizar"
                : "Crear",
              denyButtonText: "Cancelar",
            }).then((result) => {
              if (result.isConfirmed) {
                const formData = new FormData();
                formData.append("sensorId", dataTempExt.value.sensorId);
                formData.append("nombre", dataTempExt.value.nombre);
                formData.append(
                  "fechaInicio",
                  dayjs(dataTempExt.value.fechaInicio).format("YYYY-MM-DD")
                );
                formData.append(
                  "fechaFinal",
                  dayjs(dataTempExt.value.fechaFinal).format("YYYY-MM-DD")
                );
                formData.append(
                  "calibraciones",
                  JSON.stringify(dataTempExt.value.calibraciones)
                );
                formData.append(
                  "empresaCertificadoraId",
                  dataTempExt.value.empresaCertificadoraId
                );
                formData.append("pdfCertificado", pdfTld.value.files[0]);
                if (dataTempExt.value.isEditing) {
                  const id = dataTempExt.value.id;
                  if (id) {
                    updateCalibracion(id, formData)
                      .then((res) => {
                        if (res.status === 200) {
                          const input = pdfTld.value;
                          input.type = "text";
                          input.type = "file";
                          resetForm("TLD");
                          Swal.fire({
                            icon: "success",
                            title: res.data.message,
                          });
                        }
                      })
                      .catch(({ response }) => {
                        Swal.fire({
                          icon: "error",
                          title: response.data.message,
                        });
                      });
                  }
                } else {
                  crearCalibracion(formData)
                    .then((res) => {
                      temExternaForm.value.resetFields();
                      const input = pdfTld.value;
                      input.type = "text";
                      input.type = "file";

                      dataTempExt.value.calibraciones = [
                        { indicacion: 0, valor: 0 },
                      ];
                      Swal.fire({
                        icon: "success",
                        title: res.data.message,
                      });
                    })
                    .catch(({ response }) => {
                      console.error(response, "error");
                      Swal.fire({
                        icon: "error",
                        title: response.data.message,
                      });
                    });
                }
              }
            });
          } else {
            console.error("error submit!", fields);
          }
        });
      } else if (tipo === "HR") {
        await humedadForm.value.validate((valid, fields) => {
          if (valid) {
            Swal.fire({
              title: dataHumedad.value.isEditing
                ? "¿ Desea guardar los cambios realizados ? "
                : "¿ Desea crear la calibración ? ",
              icon: "question",
              showDenyButton: true,
              showCancelButton: true,
              confirmButtonText: dataHumedad.value.isEditing
                ? "Actualizar"
                : "Crear",
              denyButtonText: "Cancelar",
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                const formData = new FormData();
                formData.append("sensorId", dataHumedad.value.sensorId);
                formData.append("nombre", dataHumedad.value.nombre);
                formData.append(
                  "fechaInicio",
                  dayjs(dataHumedad.value.fechaInicio).format("YYYY-MM-DD")
                );
                formData.append(
                  "fechaFinal",
                  dayjs(dataHumedad.value.fechaFinal).format("YYYY-MM-DD")
                );
                formData.append(
                  "calibraciones",
                  JSON.stringify(dataHumedad.value.calibraciones)
                );
                formData.append(
                  "empresaCertificadoraId",
                  dataHumedad.value.empresaCertificadoraId
                );
                formData.append("pdfCertificado", pdfHm.value.files[0]);
                if (dataHumedad.value.isEditing) {
                  const id = dataHumedad.value.id;
                  if (id) {
                    updateCalibracion(id, formData)
                      .then((res) => {
                        if (res.status === 200) {
                          const input = pdfHm.value;
                          input.type = "text";
                          input.type = "file";
                          resetForm("HR");
                          Swal.fire({
                            icon: "success",
                            title: res.data.message,
                          });
                        }
                      })
                      .catch(({ response }) => {
                        Swal.fire({
                          icon: "error",
                          title: response.data.message,
                        });
                      });
                  }
                } else {
                  crearCalibracion(formData)
                    .then((res) => {
                      humedadForm.value.resetFields();
                      const input = pdfHm.value;
                      input.type = "text";
                      input.type = "file";
                      dataHumedad.value.calibraciones = [
                        { indicacion: 0, valor: 0 },
                      ];
                      Swal.fire({
                        icon: "success",
                        title: res.data.message,
                      });
                    })
                    .catch(({ response }) => {
                      console.error(response, "error");
                      Swal.fire({
                        icon: "error",
                        title: response.data.message,
                      });
                    });
                }
              }
            });
          } else {
            console.error("error submit!", fields);
          }
        });
      }
    };
    /**
     * @description Obtener las empresas certificadoras
     */
    const fetchEmpresasCertificadoras = async () => {
      getEmpresasCertificadoras()
        .then((res) => {
          empresasCertf.value = res.data;
        })
        .catch((err) => {
          console.error(err.message);
        });
    };
    const agregarCalibracion = (tipo) => {
      if (tipo === "TAMB") {
        dataTempInt.value.calibraciones.push({ indicacion: 0, valor: 0 });
      } else if (tipo === "TLD") {
        dataTempExt.value.calibraciones.push({ indicacion: 0, valor: 0 });
      } else if (tipo === "HR") {
        dataHumedad.value.calibraciones.push({ indicacion: 0, valor: 0 });
      }
    };

    const eliminarCalibracion = (tipo, index) => {
      if (tipo === "TAMB") {
        dataTempInt.value.calibraciones.splice(index, 1);
      } else if (tipo === "TLD") {
        dataTempExt.value.calibraciones.splice(index, 1);
      } else if (tipo === "HR") {
        dataHumedad.value.calibraciones.splice(index, 1);
      }
    };

    const dynamicRules = (index, tipo) => {
      let rules = [
        {
          required: true,
          message: "la indicación es requerida",
          trigger: "blur",
        },
      ];
      if (index > 0) {
        if (tipo === "TAMB") {
          rules.push({
            type: "number",
            min: dataTempInt.value.calibraciones[index - 1].indicacion,
            message: `la indicación debe ser mayor a ${
              dataTempInt.value.calibraciones[index - 1].indicacion
            }`,
            trigger: "change",
          });
        } else if (tipo === "TLD") {
          rules.push({
            type: "number",
            min: dataTempExt.value.calibraciones[index - 1].indicacion,
            message: `la indicación debe ser mayor a ${
              dataTempExt.value.calibraciones[index - 1].indicacion
            }`,
            trigger: "change",
          });
        } else if (tipo === "HR") {
          rules.push({
            type: "number",
            min: dataHumedad.value.calibraciones[index - 1].indicacion,
            message: `la indicación debe ser mayor a ${
              dataHumedad.value.calibraciones[index - 1].indicacion
            }`,
            trigger: "change",
          });
        }
        return rules;
      } else {
        return rules;
      }
    };

    const openHistorial = async () => {
      await fetchHistorial();
      category.value = "Historial";
    };

    const fetchHistorial = async () => {
      let params = {
        page: paginate.value.page,
        paginate: paginate.value.pageSize,
        order: orden.value,
        filters: filters.value,
      };
      if (tipoForm.value === "TAMB") {
        params.sensorId = dataTempInt.value.sensorId;
      } else if (tipoForm.value === "TLD") {
        params.sensorId = dataTempExt.value.sensorId;
      } else if (tipoForm.value === "HR") {
        params.sensorId = dataHumedad.value.sensorId;
      }
      await getHistorialCalibracion(params)
        .then((res) => {
          res.data.data.map((item) => {
            item.fechaInicio = dayjs(item.fechaInicio).format("DD/MM/YYYY");
            item.fechaFinal = dayjs(item.fechaFinal).format("DD/MM/YYYY");
            switch (item.sensor[0].tipo) {
              case 1:
                item.tipo = "TAMB";
                break;
              case 2:
                item.tipo = "TLD";
                break;
              case 3:
                item.tipo = "HR";
                break;
            }
            return item;
          });
          tableData.value = res.data;
          paginate.value.page = res.data.page;
          paginate.value.total = res.data.total;
          paginate.value.last_page = res.data.last_page;
          paginate.value.pageSize = res.data.per_page;
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const sortChange = (e) => {
      orden.value.column = e.prop;
      orden.value.dir = e.order === "descending" ? -1 : 1;
      fetchHistorial();
    };

    const verPdf = (id_calibracion) => {
      const token = window.localStorage.getItem("id_token");
      const url = `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/calibraciones/documento/pdf?token=${token}`;
      const res = axios.getUri({
        url,
        params: {
          calibracionId: id_calibracion,
        },
      });
      window.open(res, "_blank");
    };
    const dispositivoId = computed(() => {
      return props.datosDispo._id;
    });

    const editarCalibracion = (id) => {
      getOneCalibracion(id)
        .then((res) => {
          if (res.data.length > 0) {
            if (tipoForm.value === "TAMB") {
              dataTempInt.value.id = res.data[0]._id;
              dataTempInt.value.calibraciones = res.data[0].calibraciones;
              dataTempInt.value.sensorId = res.data[0].sensorId;
              dataTempInt.value.tipoSensor = res.data[0].sensor[0].tipo;
              dataTempInt.value.nombre = res.data[0].nombre;
              dataTempInt.value.fechaInicio = dayjs(res.data[0].fechaInicio).$d;
              dataTempInt.value.fechaFinal = dayjs(res.data[0].fechaFinal).$d;
              dataTempInt.value.isEditing = true;
              dataTempInt.value.empresaCertificadoraId =
                res.data[0].empresaCertificadoraId;
              dataTempInt.value.pdfCertificado =
                "certificacion-" + tipoForm.value + ".pdf";
            } else if (tipoForm.value === "TLD") {
              dataTempExt.value.id = res.data[0]._id;
              dataTempExt.value.calibraciones = res.data[0].calibraciones;
              dataTempExt.value.sensorId = res.data[0].sensorId;
              dataTempExt.value.tipoSensor = res.data[0].sensor[0].tipo;
              dataTempExt.value.nombre = res.data[0].nombre;
              dataTempExt.value.fechaInicio = dayjs(res.data[0].fechaInicio).$d;
              dataTempExt.value.fechaFinal = dayjs(res.data[0].fechaFinal).$d;
              dataTempExt.value.isEditing = true;
              dataTempExt.value.empresaCertificadoraId =
                res.data[0].empresaCertificadoraId;
              dataTempExt.value.pdfCertificado =
                "certificacion-" + tipoForm.value + ".pdf";
            } else if (tipoForm.value === "HR") {
              dataHumedad.value.id = res.data[0]._id;
              dataHumedad.value.calibraciones = res.data[0].calibraciones;
              dataHumedad.value.sensorId = res.data[0].sensorId;
              dataHumedad.value.tipoSensor = res.data[0].sensor[0].tipo;
              dataHumedad.value.nombre = res.data[0].nombre;
              dataHumedad.value.fechaInicio = dayjs(res.data[0].fechaInicio).$d;
              dataHumedad.value.fechaFinal = dayjs(res.data[0].fechaFinal).$d;
              dataHumedad.value.isEditing = true;
              dataHumedad.value.empresaCertificadoraId =
                res.data[0].empresaCertificadoraId;
              dataHumedad.value.pdfCertificado =
                "certificacion-" + tipoForm.value + ".pdf";
            }
            category.value = "Calibracion";
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const resetForm = (tipo) => {
      if (tipo === "TAMB") {
        dataTempInt.value.isEditing = false;
        dataTempInt.value.id = null;
        dataTempInt.value.calibraciones = [{ indicacion: 0, valor: 0 }];
        dataTempInt.value.fechaInicio = dayjs(dayjs().format("YYYY-MM-DD")).$d;
        dataTempInt.value.fechaFinal = dayjs(dayjs().format("YYYY-MM-DD")).$d;
        dataTempInt.value.empresaCertificadoraId = "";
        dataTempInt.value.pdfCertificado = "";
      } else if (tipo === "TLD") {
        dataTempExt.value.isEditing = false;
        dataTempExt.value.id = null;
        dataTempExt.value.calibraciones = [{ indicacion: 0, valor: 0 }];
        dataTempExt.value.fechaInicio = dayjs(dayjs().format("YYYY-MM-DD")).$d;
        dataTempExt.value.fechaFinal = dayjs(dayjs().format("YYYY-MM-DD")).$d;
        dataTempExt.value.empresaCertificadoraId = "";
        dataTempExt.value.pdfCertificado = "";
      } else if (tipo === "HR") {
        dataHumedad.value.isEditing = false;
        dataHumedad.value.id = null;
        dataHumedad.value.calibraciones = [{ indicacion: 0, valor: 0 }];
        dataHumedad.value.fechaInicio = dayjs(dayjs().format("YYYY-MM-DD")).$d;
        dataHumedad.value.fechaFinal = dayjs(dayjs().format("YYYY-MM-DD")).$d;
        dataHumedad.value.empresaCertificadoraId = "";
        dataHumedad.value.pdfCertificado = "";
      }
      // openHistorial();
    };

    const disabledDate = (time) => {
      if (tipoForm.value === "TAMB") {
        return (
          dayjs(dataTempInt.value.fechaInicio).$d.getTime() > time.getTime()
        );
      } else if (tipoForm.value === "TLD") {
        return (
          dayjs(dataTempExt.value.fechaInicio).$d.getTime() > time.getTime()
        );
      } else if (tipoForm.value === "HR") {
        return (
          dayjs(dataHumedad.value.fechaInicio).$d.getTime() > time.getTime()
        );
      }
    };
    const disableDateFilters = (time) => {
      if (filters.value[0]) {
        return dayjs(filters.value[0]).$d.getTime() > time.getTime();
      }
    };
    onMounted(async () => {
      await fetchEmpresasCertificadoras();
    });

    watch(
      () => props.datosDispo,
      () => {
        fetchSensoresByDispositivo(dispositivoId.value);
      }
    );
    watch(
      () => filters.value[0],
      () => {
        fetchHistorial();
      }
    );
    watch(
      () => filters.value[1],
      () => {
        fetchHistorial();
      }
    );
    watch(
      () => paginate.value.page,
      () => {
        fetchHistorial();
      }
    );

    return {
      calibrationModalButton,
      category,
      empresasCertf,
      filters,
      tableData,
      pdfTld,
      pdfHm,
      pdfTamb,
      paginate,
      dataTempInt,
      dataTempExt,
      dataHumedad,
      formRules,
      tipoForm,
      temInternaForm,
      temExternaForm,
      humedadForm,
      orden,
      toggle,
      cargarPdf,
      uploadPDf,
      submitForm,
      agregarCalibracion,
      eliminarCalibracion,
      dynamicRules,
      openHistorial,
      verPdf,
      sortChange,
      editarCalibracion,
      resetForm,
      disabledDate,
      disableDateFilters,
    };
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 990px;
}
.modal-content {
  border-radius: 1rem;
}
.modal-header {
  border-radius: 1rem;
  padding: 1rem 1rem;
  background-image: url("/img/fondo-header-histo.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: none;
}
.texto-paginator {
  padding-left: 15rem;
}
.el-form-item {
  margin-bottom: 0;
}
.table-responsive {
  overflow-y: auto;
  max-height: 300px;
}
@media (max-width: 991px) {
  .card-body {
    padding: 1em 1em;
  }
  .texto-paginator {
    padding-left: 0rem;
  }
}
</style>
