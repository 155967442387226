import ApiService from "@/core/services/ApiService";

export const verSensoresByDispositivo = (_id) => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/sensores/sensoresByDispositivo/${_id}`
  );
};

export const getEmpresasCertificadoras = () => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/certificaciones/empresas`
  );
};

export const crearCalibracion = (payload = {}) => {
  return ApiService.post(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/calibraciones/sensor`,
    payload
  );
};
export const getHistorialCalibracion = (params = {}) => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/calibraciones/find`,
    "",
    { params }
  );
};
export const getOneCalibracion = (id) => {
  return ApiService.get(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/calibraciones/find/${id}`
  );
};
export const updateCalibracion = (id, params = {}) => {
  return ApiService.put(
    `${process.env.VUE_APP_MICROSERVICE_API_EQUIPOS}/v2/calibraciones/sensor/${id}`,
    params
  );
};

export default {
  crearCalibracion,
  verSensoresByDispositivo,
  getEmpresasCertificadoras,
  getHistorialCalibracion,
  getOneCalibracion,
  updateCalibracion,
};
