<template>
  <!--begin::Mixed Widget 5-->
  <div :class="widgetClasses" class="card" id="div_card">
    <div v-if="loading.showCarga">
      <Skeleton
        :width="loading.widthCarga"
        :height="loading.heightCarga"
        animation="wave"
        borderRadius="10px"
      />
    </div>
    <!--begin::Beader-->
    <div class="card-header border-0 pt-5 pb-1 header-b-grafica">
      <div class="card-toolbar d-flex justify-content-between">
        <!--begin::Menu-->
        <div class="caja-botones w-md-auto w-100">
          <button
            @click="changeTipoGrafica('Temperatura')"
            :class="`btn btn-sm border-0 w-md-auto w-50 py-2 text-white b-tipo ${
              tipoGrafica === 'Temperatura' ? 'bg-blue' : ''
            }`"
          >
            <i class="bi bi-thermometer-high text-white"></i> Temperatura
          </button>
          <button
            @click="changeTipoGrafica('Humedad')"
            :class="`btn btn-sm border-0 w-md-auto w-50 py-2 text-white b-tipo ${
              tipoGrafica === 'Humedad' ? 'bg-blue' : ''
            }`"
          >
            <i class="bi bi-droplet-fill text-white"></i> Humedad
          </button>
        </div>

        <div
          class="d-flex flex-xl-row flex-lg-row flex-sm-column flex-column mt-4 mt-md-0 align-items-center w-xl-auto w-100 justify-content-end"
        >
          <el-config-provider :locale="locale">
            <el-date-picker
              v-model="fechaCustom"
              type="date"
              :clearable="false"
              placeholder="Seleccione una fecha"
              class="pe-0 pe-md-4 w-md-auto w-100"
              size="small"
            />
          </el-config-provider>

          <div
            class="d-flex justify-content-center caja-rangos w-md-auto w-100"
          >
            <button
              @click="changeRangoData('Hoy')"
              :class="`btn btn-sm border-0 px-4 py-2 ${
                rangoData === 'Hoy' ? 'bg-dark text-white' : 'text-muted'
              }`"
            >
              Hoy
            </button>
            <button
              @click="changeRangoData('Semana')"
              :class="`btn btn-sm border-0 px-4 py-2 ${
                rangoData === 'Semana' ? 'bg-dark text-white' : 'text-muted'
              }`"
            >
              Esta Semana
            </button>
            <button
              @click="changeRangoData('Mes')"
              :class="`btn btn-sm border-0 px-4 py-2 ${
                rangoData === 'Mes' ? 'bg-dark text-white' : 'text-muted'
              }`"
            >
              Este Mes
            </button>
            <button
              @click="changeRangoData('Año')"
              :class="`btn btn-sm border-0 px-4 py-2 ${
                rangoData === 'Año' ? 'bg-dark text-white' : 'text-muted'
              }`"
            >
              Este Año
            </button>
          </div>
        </div>

        <!--end::Menu-->
      </div>
    </div>
    <!--end::Header-->

    <!--begin::Body-->
    <div class="card-body d-flex flex-column mt-4 pe-2 ps-2">
      <div class="tituloFecha">
        <span>{{ titulo }}</span>
      </div>
      <!--begin::Chart-->
      <div ref="elGrafica" style="width: 100%; height: 300px"></div>
      <!--end::Chart-->
    </div>
    <!--end::Body-->
  </div>
  <!--end::Mixed Widget 5-->
</template>

<script>
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
// import { getCSSVariableValue } from "@/assets/ts/_utils";
import dayjs from "dayjs";
import Skeleton from "primevue/skeleton";
import "dayjs/locale/es";
dayjs.locale("es");
import { useRouter, useRoute } from "vue-router";
import * as echarts from "echarts";
import orderBy from "lodash/orderBy";
import { verDispositivosIdGrafica } from "@/services/dispositivos";
import esEl from "element-plus/lib/locale/lang/es";

export default defineComponent({
  name: "dispositivoGrafica",
  components: {
    Skeleton,
  },
  emits: ["changeFocusedCard", "changeFechaCustom"],
  props: {
    widgetClasses: String,
    datos: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const router = useRouter();
    const route = useRoute();
    const datosDispo = ref({});
    const loading = ref({
      widthCarga: "",
      heightCarga: "",
      showCarga: false,
    });
    const TextoTermo = (tipo) => {
      switch (tipo) {
        case 0:
          return "text-green";
        case 1:
          return "text-blue";
        case 2:
          return "text-orange";
        default:
          return "";
      }
    };

    const elGrafica = ref(null);
    let chart = reactive({});
    const tipoGrafica = ref("Temperatura");
    const rangoData = ref("Hoy");
    const fechaCustom = ref(dayjs().format("YYYY-MM-DD"));
    const titulo = ref("11 de mayo de 2022");
    const focusCard = ref([false, false, true]);

    const fetchData = async () => {
      const { id } = route.params;
      let periodoFecha = "";
      let periodo = 0;

      if (rangoData.value === "") {
        periodoFecha = dayjs(fechaCustom.value).format("YYYY-MM-DD");
        periodo = 1;
      } else if (rangoData.value === "Hoy") {
        // periodoFecha = "2022-01-01"; //cambiar por dia de hoy
        periodoFecha = dayjs().format("YYYY-MM-DD");
        periodo = 1;
      } else if (rangoData.value === "Semana") {
        // periodoFecha = dayjs("2022-01-01").startOf("week").format("YYYY-MM-DD"); //cambiar por dia de hoy
        periodoFecha = dayjs().startOf("week").format("YYYY-MM-DD");
        periodo = 2;
      } else if (rangoData.value === "Mes") {
        // periodoFecha = dayjs("2022-01-01")
        //   .startOf("month")
        //   .format("YYYY-MM-DD");
        periodoFecha = dayjs().startOf("month").format("YYYY-MM-DD");
        periodo = 3;
      } else if (rangoData.value === "Año") {
        // periodoFecha = dayjs("2022-01-01").startOf("year").format("YYYY-MM-DD");
        periodoFecha = dayjs().startOf("year").format("YYYY-MM-DD");
        periodo = 4;
      }
      return await verDispositivosIdGrafica(id, {
        periodo: periodo,
        fechaCustom: fechaCustom.value,
        periodoFecha: periodoFecha,
      })
        .then(({ data }) => {
          datosDispo.value = data;
        })
        .catch((err) => {
          console.error(err);
        });
    };

    const setTextTitulo = () => {
      let fechaInicialTemp = "";
      let fechaInicial = "";
      let fechaFinalTemp = "";
      let fechaFinal = "";
      switch (rangoData.value) {
        case "":
          titulo.value = dayjs(fechaCustom.value).format(
            "DD [de] MMMM [de] YYYY"
          );
          break;
        case "Hoy":
          titulo.value = dayjs().format("DD [de] MMMM [de] YYYY");
          break;
        case "Semana":
          fechaInicialTemp = dayjs().startOf("week").format("YYYY-MM-DD");
          fechaFinalTemp = dayjs()
            .startOf("week")
            .add(6, "days")
            .format("YYYY-MM-DD");
          if (
            dayjs(fechaInicialTemp).format("MM") ===
            dayjs(fechaFinalTemp).format("MM")
          ) {
            if (
              dayjs(fechaInicialTemp).format("YYYY") ===
              dayjs(fechaFinalTemp).format("YYYY")
            ) {
              fechaInicial = dayjs(fechaInicialTemp).format("DD");
              fechaFinal = dayjs(fechaFinalTemp).format(
                "DD [de] MMMM [de] YYYY"
              );
            } else {
              fechaInicial = dayjs(fechaInicialTemp).format(
                "DD [de] MMMM [de] YYYY"
              );
              fechaFinal = dayjs(fechaFinalTemp).format(
                "DD [de] MMMM [de] YYYY"
              );
            }
          } else if (
            dayjs(fechaInicialTemp).format("YYYY") ===
            dayjs(fechaFinalTemp).format("YYYY")
          ) {
            fechaInicial = dayjs(fechaInicialTemp).format("DD [de] MMMM");
            fechaFinal = dayjs(fechaFinalTemp).format("DD [de] MMMM [de] YYYY");
          } else {
            fechaInicial = dayjs(fechaInicialTemp).format(
              "DD [de] MMMM [de] YYYY"
            );
            fechaFinal = dayjs(fechaFinalTemp).format("DD [de] MMMM [de] YYYY");
          }
          titulo.value = fechaInicial + " - " + fechaFinal;
          break;
        case "Mes":
          titulo.value = dayjs().startOf("month").format("MMMM [de] YYYY");
          break;
        case "Año":
          fechaInicial = dayjs().startOf("year").format("M/YYYY");
          fechaFinal = dayjs()
            .startOf("year")
            .add(364, "days")
            .format("M/YYYY");
          titulo.value = fechaInicial + " - " + fechaFinal;
          break;
      }
    };
    const changeTipoGrafica = (tipo) => {
      setLoading();
      tipoGrafica.value = tipo;
      if (tipoGrafica.value === "Temperatura") {
        focusCard.value = [false, false, true];
      } else {
        focusCard.value = [true, true, false];
      }
      emit("changeFocusedCard", focusCard.value);
      drawEchart();
      reloadChart();
    };
    const changeRangoData = async (rango) => {
      if (rango === "Hoy") {
        fechaCustom.value = dayjs().format("YYYY-MM-DD");
      } else {
        fechaCustom.value = null;
      }
      setLoading();
      // tipoGrafica.value = "Temperatura";
      rangoData.value = rango;
      setTextTitulo();
      drawEchart();
      datosDispo.value = [];
      await fetchData();
      await reloadChart();
    };

    const drawEchart = () => {
      //aplicar zoom para grafica cuando el width sea < 768  window.innerWidth
      if (
        chart !== null &&
        chart !== "" &&
        chart !== undefined &&
        Object.keys(chart).length > 0
      ) {
        chart.dispose(); // borra la instancia en caso de estar creada
      }
      chart = echarts.init(elGrafica.value, "light");
      let dataZoomSettings = [];
      let xData = setXAxisData();

      if (fechaCustom.value) {
        dataZoomSettings = [
          {
            type: "slider",
            show: false,
            filterMode: "none",
            xAxisIndex: [0],
            startValue: "00:00",
            endValue: xData[xData.length - 1],
          },
          {
            type: "inside",
            show: false,
            filterMode: "none",
            xAxisIndex: [0],
            start: "00:00",
            end: xData[xData.length - 1],
          },
        ];
      }
      if (rangoData.value === "Hoy") {
        dataZoomSettings = [
          {
            type: "slider",
            show: false,
            filterMode: "none",
            xAxisIndex: [0],
            startValue: "00:00",
            endValue: xData[xData.length - 1],
          },
          {
            type: "inside",
            show: false,
            filterMode: "none",
            xAxisIndex: [0],
            start: "00:00",
            end: xData[xData.length - 1],
          },
        ];
      }
      let option = {
        legend: {
          itemGap: window.innerWidth < 768 ? 5 : 10,
          padding: window.innerWidth < 768 ? [0, 0, 0, 0] : [5, 5, 5, 5],
          itemWidth: window.innerWidth < 768 ? 10 : 15,
        },
        grid: {
          // left: "5%",
          right: "3%",
          bottom: "3%",
          height: "85%",
          width: "96%",
          containLabel: true,
        },
        dataZoom: dataZoomSettings,
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: xData,
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: `{value}${
              tipoGrafica.value === "Temperatura" ? "°C" : "%"
            }`,
          },
        },
        series: [],
      };
      chart.setOption(option, true);
    };

    const reloadChart = async () => {
      let option = {};
      let tooltipSettings = {};

      let seriesData = [];
      let dataSensorInt = datosDispo.value.sensores.filter((s) => {
        return s.tipo === 1;
      });
      let dataSensorExt = datosDispo.value.sensores.filter((s) => {
        return s.tipo === 2;
      });
      let dataSensorHum = datosDispo.value.sensores.filter((s) => {
        return s.tipo === 3;
      });
      if (rangoData.value === "Hoy" || rangoData.value === "") {
        let dataTempInt = dataSensorInt[0].registros.map((r) => {
          return r[0];
        });
        let dataTempExt = dataSensorExt[0].registros.map((r) => {
          return r[0];
        });
        let dataHum = dataSensorHum[0].registros.map((r) => {
          return r[0];
        });
        tooltipSettings = {
          trigger: "axis",
          position: (pos) => {
            if (window.innerWidth > 1024) {
              return pos[0];
            } else {
              return ["5%", "0"];
            }
          },
          formatter(a) {
            if (tipoGrafica.value === "Temperatura") {
              let formato = `
                ${a[0].name}
                <br>
                <div style="display:flex; align-items: center">
                    <div style="width: 10px ; height: 10px; border-radius: 50% ; background-color:${
                      a[0].color
                    }">
                    </div>
                    ${a[0].seriesName} : ${
                typeof a[0].value === "undefined" ? "-" : a[0].value
              }°
                </div>`;
              if (a.length > 1) {
                formato += `<div style="display:flex; align-items: center"> <div style="width: 10px ; height: 10px; border-radius: 50% ; background-color:${
                  a[1].color
                }"></div>${a[1].seriesName} : ${
                  typeof a[1].value === "undefined" ? "-" : a[1].value
                }°</div>`;
              }
              return formato;
            } else {
              return `${
                a[0].name
              }<br/><div style="display:flex; align-items: center"><div style="display:flex; align-items: center"> <div style="width: 10px ; height: 10px; border-radius: 50% ; background-color:${
                a[0].color
              }"></div>${a[0].seriesName} : ${
                typeof a[0].value === "undefined" ? "-" : a[0].value
              }% </div>`;
            }
          },
        };
        if (tipoGrafica.value === "Temperatura") {
          seriesData = [
            {
              name: "Temperatura Interna",
              type: "line",
              data: dataTempInt,
              color: "#6ACE53",
            },
            {
              name: "Temperatura Externa",
              type: "line",
              data: dataTempExt,
              color: "#E5B239",
            },
          ];
        } else {
          seriesData = [
            {
              name: "Humedad",
              type: "line",
              data: dataHum,
              color: "#62C7DC",
            },
          ];
        }
      } else {
        let dataTempInt = [[], []];
        dataTempInt[0] = dataSensorInt[0].registros[0].map((r) => {
          return r[0];
        });
        dataTempInt[1] = dataSensorInt[0].registros[1].map((r) => {
          return r[0];
        });

        let dataTempExt = [[], []];
        dataTempExt[0] = dataSensorExt[0].registros[0].map((r) => {
          return r[0];
        });
        dataTempExt[1] = dataSensorExt[0].registros[1].map((r) => {
          return r[0];
        });

        let dataHum = [[], []];
        dataHum[0] = dataSensorHum[0].registros[0].map((r) => {
          return r[0];
        });
        dataHum[1] = dataSensorHum[0].registros[1].map((r) => {
          return r[0];
        });

        if (tipoGrafica.value === "Temperatura") {
          tooltipSettings = {
            trigger: "item",
            axisPointer: {
              type: "cross",
            },
            position: (pos) => {
              if (window.innerWidth > 1024) {
                return pos[0];
              } else {
                return ["5%", "0"];
              }
            },
            formatter(a) {
              let fechaActual = "";
              if (rangoData.value === "Semana") {
                fechaActual = dayjs()
                  .startOf("week")
                  .add(a.dataIndex, "days")
                  .format("D [de] MMMM");
              } else if (rangoData.value === "Mes") {
                fechaActual = dayjs()
                  .startOf("month")
                  .add(a.dataIndex, "days")
                  .format("DD [de] MMMM");
              } else if (rangoData.value === "Año") {
                fechaActual = dayjs()
                  .startOf("year")
                  .add(a.dataIndex, "months")
                  .format("MMMM");
              }
              let htmlString = ``;
              if (a.seriesName === "Temperatura Interna") {
                htmlString = `
                    <div style="
                      display: flex;
                      width: 200px;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                      border-bottom: 1px solid;
                      padding-bottom: 5px;
                    ">
                      <span >${a.seriesName}</span>
                      <span>${fechaActual}</span>
                    </div>
                    <div style="padding-top: 5px">
                      <span style="font-weight: bold;"> Maxima : </span> <span>${
                        dataTempInt[0][a.dataIndex]
                      }°</span>
                      <br>
                      <span style="font-weight: bold;"> Minima :</span> <span> ${
                        dataTempInt[1][a.dataIndex]
                      }°</span>
                    </div>
                  `;
              } else {
                htmlString = `
                    <div style="
                      display: flex;
                      width: 200px;
                      justify-content: center;
                      align-items: center;
                      flex-direction: column;
                      border-bottom: 1px solid;
                      padding-bottom: 5px;
                    ">
                      <span >${a.seriesName}</span>
                      <span>${fechaActual}</span>
                    </div>
                    <div>
                      <span style="font-weight: bold;"> Maxima : </span> <span>${
                        dataTempExt[0][a.dataIndex]
                      }°</span>
                      <br>
                      <span style="font-weight: bold;"> Minima :</span> <span> ${
                        dataTempExt[1][a.dataIndex]
                      }°</span>
                    </div>
                  `;
              }
              return htmlString;
            },
          };
          seriesData = [
            {
              name: "Temperatura Interna",
              type: "line",
              data: dataTempInt[0],
              color: "#6ACE53",
            },
            {
              name: "Temperatura Externa",
              type: "line",
              data: dataTempExt[0],
              color: "#E5B239",
            },
          ];
        } else {
          tooltipSettings = {
            trigger: "item",
            axisPointer: {
              type: "cross",
            },
            position: (pos) => {
              if (window.innerWidth > 1024) {
                return pos[0];
              } else {
                return ["5%", "0"];
              }
            },
            formatter(a) {
              return `
                    <div style="
                      display: flex;
                      width: 275px;
                      align-content: center;
                      border-bottom: 1px solid;
                      padding-bottom: 5px;
                    ">
                      <span>${a.seriesName} - ${a.name}</span>
                    </div>
                    <div>
                      <span style="font-weight: bold;"> Maxima : </span> <span>${
                        dataHum[0][a.dataIndex]
                      } %</span>
                      <br>
                      <span style="font-weight: bold;"> Minima :</span> <span> ${
                        dataHum[1][a.dataIndex]
                      } %</span>
                    </div>
                  `;
            },
          };
          seriesData = [
            {
              name: "Humedad",
              type: "line",
              data: dataHum[0],
              color: "#62C7DC",
            },
          ];
        }
      }
      option = {
        tooltip: tooltipSettings,
        legend: {
          icon: "circle",
          data:
            tipoGrafica.value === "Temperatura"
              ? ["Temperatura Interna", "Temperatura Externa"]
              : ["Humedad"],
        },
        series: seriesData,
      };
      chart.setOption(option);
      setLoading();
    };

    const setXAxisData = () => {
      let xAxisData = [];
      let dataSensorInt = datosDispo.value.sensores.filter((s) => {
        return s.tipo === 1;
      });
      let dataSensorHum = datosDispo.value.sensores.filter((s) => {
        return s.tipo === 3;
      });
      if (fechaCustom.value) {
        if (tipoGrafica.value === "Temperatura") {
          xAxisData = dataSensorInt[0].registros.map((r) => {
            return r[1];
          });
        } else {
          xAxisData = dataSensorHum[0].registros.map((r) => {
            return r[1];
          });
        }
        if (xAxisData.length > 0) {
          /*se asigna una fecha cualquiera, ya que solo se desea saber cuantos minutos se deben agregar
           * con el fin de que se visualice las 24 horas dentro de la gráfica*/
          let difminutos = dayjs("2021-04-05 23:59").diff(
            "2021-04-05 " + xAxisData[xAxisData.length - 1],
            "minutes"
          );
          let ultimoRegistro = "2021-04-05 " + xAxisData[xAxisData.length - 1];
          for (let i = 0; i <= difminutos; i++) {
            xAxisData.push(
              dayjs(ultimoRegistro).add(i, "minutes").format("HH:mm")
            );
          }
        }
        // let horasCicloIni = dayjs(fechaCustom.value).format("YYYY-MM-DD 00:00");
        // for (let i = 0; i < 480; i++) {
        //   let horasCiclo = dayjs(horasCicloIni)
        //     .add(i * 3, "m")
        //     .format("HH:mm");
        //   xAxisData.push(horasCiclo);
        // }
      } else if (rangoData.value === "Hoy") {
        if (tipoGrafica.value === "Temperatura") {
          xAxisData = dataSensorInt[0].registros.map((r) => {
            return r[1];
          });
        } else {
          xAxisData = dataSensorHum[0].registros.map((r) => {
            return r[1];
          });
        }
        if (xAxisData.length > 0) {
          let difminutos = dayjs("2021-04-05 23:59").diff(
            "2021-04-05 " + xAxisData[xAxisData.length - 1],
            "minutes"
          );
          let ultimoRegistro = "2021-04-05 " + xAxisData[xAxisData.length - 1];
          for (let i = 0; i <= difminutos; i++) {
            xAxisData.push(
              dayjs(ultimoRegistro).add(i, "minutes").format("HH:mm")
            );
          }
        }
        // let horasCicloIni = dayjs().format("YYYY-MM-DD 00:00");
        // for (let i = 0; i < 480; i++) {
        //   let horasCiclo = dayjs(horasCicloIni)
        //     .add(i * 3, "m")
        //     .format("HH:mm");
        //   xAxisData.push(horasCiclo);
        // }
      } else if (rangoData.value === "Semana") {
        let semanaCliclo = dayjs().startOf("week").format("YYYY-MM-DD");
        for (let i = 0; i < 7; i++) {
          let fechaCiclo = dayjs(semanaCliclo)
            .add(i, "days")
            .format("YYYY-MM-DD");
          xAxisData.push(dayjs(fechaCiclo).format("ddd"));
        }
      } else if (rangoData.value === "Mes") {
        let mesCiclo = dayjs().startOf("month").format("YYYY-MM-DD");
        for (let i = 0; i < 30; i++) {
          let fechaCiclo = dayjs(mesCiclo).add(i, "days").format("YYYY-MM-DD");
          xAxisData.push(dayjs(fechaCiclo).format("DD"));
        }
      } else if (rangoData.value === "Año") {
        let mesCiclo = dayjs().startOf("year").format("YYYY-MM-DD");
        for (let i = 0; i < 12; i++) {
          let fechaCiclo = dayjs(mesCiclo)
            .add(i, "months")
            .format("YYYY-MM-DD");
          xAxisData.push(dayjs(fechaCiclo).format("MMM"));
        }
      }
      return xAxisData;
    };
    const verEquipo = (id) => {
      router.push({ name: "profile-equipos-ver", params: { id } });
    };
    const setLoading = () => {
      let element = document.getElementById("div_card");
      if (element) {
        loading.value.widthCarga = element.clientWidth + "px";
        loading.value.heightCarga = element.clientHeight - 10 + "px";
      }
      loading.value.showCarga = !loading.value.showCarga;
    };

    watch(
      () => fechaCustom.value,
      async () => {
        let fechaFormatCustom = dayjs(fechaCustom.value).format("YYYY-MM-DD");
        let fechaNow = dayjs().format("YYYY-MM-DD");
        emit("changeFechaCustom", fechaCustom.value);
        if (fechaFormatCustom === fechaNow && fechaCustom.value !== null) {
          rangoData.value = "Hoy";
        } else if (fechaCustom.value !== null) {
          rangoData.value = "";
          setLoading();
          await fetchData();
          drawEchart();
          await reloadChart();
          setTextTitulo();
        }
      }
    );
    // watch(
    //   () => rangoData.value,
    //   async () => {
    //     drawEchart();
    //     await reloadChart();
    //   }
    // );

    onMounted(async () => {
      setLoading();
      await fetchData();
      drawEchart();
      await reloadChart();
      setTextTitulo();
      window.addEventListener("resize", async () => {
        await drawEchart();
        await reloadChart();
        // chart.resize();
        setLoading();
      });
    });

    return {
      TextoTermo,
      drawEchart,
      setXAxisData,
      reloadChart,
      verEquipo,
      setLoading,
      changeTipoGrafica,
      changeRangoData,
      tipoGrafica,
      rangoData,
      elGrafica,
      datosDispo,
      loading,
      titulo,
      focusCard,
      fechaCustom,
      locale: esEl,
    };
  },
});
</script>
<style lang="scss" scoped>
.h-30 {
  height: 30px;
}
.card {
  box-shadow: none;
}
.card-body {
  padding: 0;
}
.p-skeleton {
  position: absolute;
  z-index: 1;
  right: 0px;
  background: #dee2e6;
}
.p-skeleton:after {
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.4),
    rgba(255, 255, 255, 0)
  );
}
.tituloFecha {
  display: flex;
  justify-content: center;
}
.tituloFecha > span {
  font-size: 16px;
  font-weight: bold;
  color: #999999;
}
.caja-botones {
  background: #cbcbcb;
  border-radius: 40px;
  box-shadow: inset 0px 4px 3px 0px rgb(0 0 0 / 25%);
}
.b-tipo {
  border-radius: 40px;
}
.header-b-grafica {
  display: unset;
}
@media (max-width: 991px) {
  .card-header {
    padding: 0px;
  }
  .tituloFecha {
    padding-bottom: 5px;
  }
  .tituloFecha span {
    font-size: 14px;
  }
  .caja-rangos {
    padding-top: 10px;
  }
}
</style>
