<template>
  <button
    class="btn bg-blue rounded-pill text-white ms-2"
    data-bs-toggle="modal"
    :data-bs-target="`#${targetModal}`"
    v-show="false"
    ref="btnShowModal"
  >
    Notificación
  </button>
  <div
    class="modal fade"
    :id="targetModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header flex-column flex-md-row">
          <div class="d-flex">
            <div class="pt-2 px-3">
              <i class="bi bi-gear-fill fs-3 text-body"></i>
            </div>
            <div>
              <span class="fs-1 fw-bolder"> Configuración </span>
            </div>
          </div>
          <div class="align-items-end">
            <img
              alt="Logo"
              src="/media/logos/logo_sens.svg"
              style="width: 30px"
            />
            <span class="fs-3 fw-bolder pe-2">{{ datosDispo.nombre }}</span>
            <span class="fs-4 text-muted fw-light">{{
              datosDispo.areaNombre
            }}</span>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-body">
          <div class="row mb-4">
            <div class="col-md-12 d-flex justify-content-center">
              <div>
                <i
                  v-if="sensorTipo === 3"
                  :class="
                    'bi bi-droplet-fill texto-valor ' + TextoTermo(sensorTipo)
                  "
                ></i>
                <img
                  v-else-if="sensorTipo === 2"
                  src="@/assets/img/TermoNaranja.png"
                  alt=""
                  style="height: 70px"
                />
                <img
                  v-else-if="sensorTipo === 1"
                  src="@/assets/img/TermoVerde.png"
                  alt=""
                  style="height: 70px"
                />
              </div>
              <span
                :class="`fs-1 fw-bolder my-auto ${TextoTermo(sensorTipo)}`"
                >{{ sensorNombre }}</span
              >
            </div>
          </div>
          <el-form
            ref="temRankForm"
            :model="model"
            :rules="formRules"
            class="demo-ruleForm"
          >
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-4 my-auto">
                    <i
                      class="bi bi-bar-chart-fill fs-1"
                      style="color: #27aae1"
                    />
                    <span
                      class="fw-bolder"
                      style="font-size: 22px; padding-left: 20px"
                      >Rango</span
                    >
                    <el-tooltip
                      class="box-item"
                      effect="dark"
                      content="Seleccione los limites del sensor. Al recopilar información fuera de los parámetros, enviaremos una alerta al correo/celular."
                      placement="top"
                    >
                      <i
                        class="bi bi-info-circle cursor-pointer"
                        style="padding-left: 20px"
                      />
                    </el-tooltip>
                  </div>
                  <div class="col-md-8">
                    <div class="row">
                      <div class="col-md-6">
                        <div class="d-flex">
                          <label for="rangoMaximo" class="my-auto m-3"
                            >Máx</label
                          >
                          <el-form-item prop="rangoMaximo">
                            <el-input-number
                              v-model="model.rangoMaximo"
                              :precision="2"
                              :step="0.1"
                              :min="model.rangoMinimo"
                            />
                          </el-form-item>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="d-flex">
                          <label for="rangoMinimo" class="my-auto m-3"
                            >Mín</label
                          >
                          <el-form-item prop="rangoMinimo">
                            <el-input-number
                              v-model="model.rangoMinimo"
                              :precision="2"
                              :step="0.1"
                              :max="model.rangoMaximo"
                            />
                          </el-form-item>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-form>
          <div class="row justify-content-center mt-5">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              ref="btnCloseModal"
              v-show="false"
            >
              Close
            </button>
            <div class="col-md-4 text-end">
              <button
                @click="onSubmit"
                name="button"
                class="btn bg-blue text-white btn-sm w-100"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { ref, reactive } from "vue";
import { alerta } from "@/utils/mensajes";
import { editarSensor } from "@/services/dispositivos";

export default {
  name: "rangoSensoresModal",
  emits: ["responseSuccess"],
  props: {
    targetModal: {
      type: String,
      default: "rangoSensoresModal",
    },
    datosDispo: Object,
  },
  setup(props, { emit }) {
    const temRankForm = ref();
    const btnShowModal = ref();
    const btnCloseModal = ref();
    const sensorNombre = ref("");
    const sensorTipo = ref(1);
    const model = reactive({
      _id: "",
      rangoMinimo: 0,
      rangoMaximo: 0,
    });
    const TextoTermo = (tipo) => {
      switch (tipo) {
        case 1:
          return "text-green";
        case 2:
          return "text-orange";
        case 3:
          return "text-blue";
        default:
          return "";
      }
    };
    const formRules = reactive({
      rangoMinimo: [
        {
          required: true,
          message: "min es requerido",
          trigger: "blur",
        },
      ],
      rangoMaximo: [
        {
          required: true,
          message: "max es requerido",
          trigger: "blur",
        },
      ],
    });

    const resetForm = () => {
      model._id = "";
      model.rangoMinimo = 0;
      model.rangoMaximo = 0;
    };
    const onSubmit = async () => {
      await temRankForm.value.validate(async (valid) => {
        if (!valid) return false;
        const payload = {
          rangoMinimo: model.rangoMinimo,
          rangoMaximo: model.rangoMaximo,
        };
        const { data } = await editarSensor(model._id, payload).catch(
          (error) => {
            console.log(error);
            btnCloseModal.value.click();
          }
        );
        if (data) {
          alerta(
            "Mensaje",
            data.message ? data.message : "Editado correctamente",
            "success"
          );
          resetForm();
          emit("responseSuccess");
          btnCloseModal.value.click();
        }
      });
    };
    const toggle = (data) => {
      resetForm();
      sensorNombre.value = data.nombre;
      sensorTipo.value = data.tipo;
      model._id = data._id;
      model.rangoMinimo = data.rangoMinimo;
      model.rangoMaximo = data.rangoMaximo;
      btnShowModal.value.click();
    };

    return {
      btnShowModal,
      btnCloseModal,
      model,
      sensorNombre,
      sensorTipo,
      formRules,
      temRankForm,
      toggle,
      onSubmit,
      TextoTermo,
    };
  },
};
</script>
<style scoped>
.texto-valor {
  font-size: 40px;
}
.modal-dialog {
  max-width: 990px;
}
.modal-content {
  border-radius: 1rem;
}
.modal-header {
  border-radius: 1rem;
  padding: 1rem 1rem;
  background-image: url("/img/fondo-header-histo.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: none;
}
.texto-paginator {
  padding-left: 15rem;
}
.el-form-item {
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .card-body {
    padding: 1em 1em;
  }
  .texto-paginator {
    padding-left: 0rem;
  }
}
</style>
