<template>
  <div
    class="modal fade"
    id="historialModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header flex-column flex-md-row">
          <div class="d-flex">
            <div class="pt-2 px-3">
              <svg
                id="Grupo_1297"
                data-name="Grupo 1297"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="21.797"
                height="20.033"
                viewBox="0 0 21.797 20.033"
                fill="#000"
              >
                <defs>
                  <clipPath id="clip-path">
                    <rect
                      id="Rectángulo_2202"
                      data-name="Rectángulo 2202"
                      width="21.797"
                      height="20.033"
                      fill="#000"
                    />
                  </clipPath>
                </defs>
                <g
                  id="Grupo_1296"
                  data-name="Grupo 1296"
                  clip-path="url(#clip-path)"
                >
                  <path
                    id="Trazado_2376"
                    data-name="Trazado 2376"
                    d="M3.17,9.756c.43,0,.792,0,1.155,0a.77.77,0,0,1,.706,1.2q-.87,1.532-1.762,3.051a.77.77,0,0,1-1.38,0Q1,12.484.126,10.952a.771.771,0,0,1,.712-1.2c.266,0,.532,0,.779,0,.1-.624.148-1.231.286-1.819A9.645,9.645,0,0,1,7.572.962,9.692,9.692,0,0,1,18.079,2.315a9.6,9.6,0,0,1,3.628,6.446,9.992,9.992,0,0,1-8.159,11.091A9.824,9.824,0,0,1,4.92,17.376a.78.78,0,1,1,1.04-1.155,8.514,8.514,0,0,0,13.462-2.629A8.515,8.515,0,1,0,3.415,8.1c-.113.534-.162,1.081-.246,1.656"
                    transform="translate(0 0)"
                    fill="#000"
                  />
                  <path
                    id="Trazado_2377"
                    data-name="Trazado 2377"
                    d="M155.728,49.657q0-1.666,0-3.333c0-.588.291-.945.772-.948s.785.357.785.961c0,2.068,0,4.135,0,6.2a.455.455,0,0,0,.2.4c1.112.88,2.216,1.771,3.327,2.655a.816.816,0,0,1,.362.774.77.77,0,0,1-1.279.48c-.632-.494-1.255-1-1.882-1.5s-1.248-1.009-1.882-1.5a.967.967,0,0,1-.4-.836c.011-1.119,0-2.238,0-3.357"
                    transform="translate(-145.604 -42.428)"
                    fill="#000"
                  />
                </g>
              </svg>
            </div>
            <div>
              <span class="fs-1 fw-bolder">Historial</span>
              <span class="fs-3 fw-bolder ps-2">{{ fechaActual }}</span>
            </div>
          </div>
          <div class="align-items-end">
            <img
              alt="Logo"
              src="/media/logos/logo_sens.svg"
              style="width: 30px"
            />
            <span class="fs-3 fw-bolder pe-2">{{ dataGeneral.nombre }}</span>
            <span class="fs-4 text-muted fw-light">{{
              dataGeneral.areaNombre
            }}</span>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-body">
          <div class="row pb-10">
            <div class="col-6 d-flex justify-content-center align-items-center">
              <div class="caja-botones h-auto">
                <button
                  @click="changeTipoGrafica('Temperatura')"
                  :class="`btn border-0 w-md-auto w-50 py-2 text-white b-tipo ${
                    tipoGrafica === 'Temperatura' ? 'bg-blue' : ''
                  }`"
                >
                  <i class="bi bi-thermometer-high text-white"></i
                  ><span class="d-md-inline d-none">Temperatura </span>
                </button>
                <button
                  @click="changeTipoGrafica('Humedad')"
                  :class="`btn border-0 w-md-auto w-50 py-2 text-white b-tipo ${
                    tipoGrafica === 'Humedad' ? 'bg-blue' : ''
                  }`"
                >
                  <i class="bi bi-droplet-fill text-white"></i>
                  <span class="d-md-inline d-none">Humedad</span>
                </button>
              </div>
            </div>
            <div class="col-6 d-flex justify-content-center">
              <el-date-picker
                v-model="fecha"
                type="date"
                :clearable="false"
                placeholder="Seleccione una fecha"
                class="w-100"
              />
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row" v-if="tipoGrafica === 'Temperatura'">
                <div v-if="mostrarTempera" class="d-flex flex-wrap">
                  <div
                    class="col-lg-6 col-md-12 col-sm-12 col-12 gx-10 d-flex justify-content-center"
                    :class="+temp.tipo === 1 ? 'border-end' : 'pt-3 '"
                    v-for="temp in dataTemp"
                    :key="temp.nombre"
                  >
                    <div class="row gx-10 ps-5">
                      <div class="col-6 d-flex align-items-center">
                        <div>
                          <img
                            src="@/assets/img/TermoVerde.png"
                            alt=""
                            v-if="temp.tipo === 1"
                            style="height: 60px"
                          />
                          <img
                            src="@/assets/img/TermoNaranja.png"
                            alt=""
                            v-if="temp.tipo === 2"
                            style="height: 60px"
                          />
                        </div>
                        <div>
                          <span
                            :class="'fs-4 fw-bolder ' + TextoTermo(temp.tipo)"
                            >{{ temp.nombre }}</span
                          >
                        </div>
                      </div>
                      <div
                        class="col-6 text-end d-flex justify-content-center pt-2"
                      >
                        <div>
                          <span
                            ><p
                              :class="
                                'fs-6 text-center fw-bolder mb-n2 ' +
                                TextoTermo(temp.tipo)
                              "
                            >
                              Actual
                            </p></span
                          >
                          <span
                            :class="
                              'texto-valor fw-bolder ' + TextoTermo(temp.tipo)
                            "
                          >
                            {{ temp.registroValor }}°
                          </span>
                        </div>
                      </div>
                      <div class="col-12 py-2 d-flex justify-content-center">
                        <span class="text-muted fs-5"
                          >Ultimo registro
                          {{ formatFecha(temp.registros[0].fecha) }}</span
                        >
                      </div>

                      <div class="row pt-3 w-100">
                        <div class="col-4 d-flex justify-content-center pb-3">
                          Fecha
                        </div>
                        <div class="col-4 d-flex justify-content-center pb-3">
                          Temperatura
                        </div>
                        <div class="col-4 d-flex justify-content-center pb-3">
                          Temp. sin calibrar
                        </div>
                      </div>
                      <div class="row">
                        <div class="d-flex justify-content-center">
                          <div class="tabla-fondo py-3 h-225px w-100">
                            <div
                              class="row text-muted pt-2"
                              v-for="(reg, index) in temp.registros"
                              :key="index"
                            >
                              <div class="col-4 d-flex justify-content-center">
                                {{ formatFecha2(reg.fecha) }}
                              </div>
                              <div class="col-4 d-flex justify-content-center">
                                {{ reg.valor }}°
                              </div>
                              <div class="col-4 d-flex justify-content-center">
                                {{ reg.valorSinCalibrar }}°
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="d-flex justify-content-center align-items-center">
                    <i
                      class="bi bi-exclamation-diamond-fill text-warning fs-1 me-3"
                    ></i>
                    <span class="fs-2 text-muted">No hay información</span>
                  </div>
                </div>
              </div>
              <div class="row" v-if="tipoGrafica === 'Humedad'">
                <div v-if="mostrarHumedad" class="d-flex flex-wrap">
                  <div class="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center pt-7">
                        <i class="bi bi-droplet-fill text-blue icon-hum"></i>
                        <span class="fs-1 fw-bolder text-blue ps-3"
                          >Humedad</span
                        >
                      </div>
                    </div>
                    <div class="row">
                      <div
                        ref="humGrafica"
                        style="width: 100%; height: 350px"
                      ></div>
                    </div>
                    <div class="row">
                      <div class="col-12 d-flex justify-content-center">
                        <span class="text-muted"
                          >Ultimo registro
                          {{
                            formatFecha(dataTemp[0].registros[0].fecha)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 col-md-12 col-sm-12 col-12 border-start">
                    <div class="row pt-3 gx-10 ps-md-15 ps-sm-1">
                      <div class="col-4 d-flex justify-content-center pb-3">
                        Fecha
                      </div>
                      <div class="col-4 d-flex justify-content-center pb-3">
                        Humedad
                      </div>
                      <div class="col-4 d-flex text-center pb-3">
                        Hum. sin calibrar
                      </div>
                      <div class="tabla-fondo py-3 h-375px">
                        <div
                          class="row text-muted pt-2"
                          v-for="(reg, index) in dataHume[0].registros"
                          :key="index"
                        >
                          <div class="col-4 d-flex justify-content-center">
                            {{ formatFecha2(reg.fecha) }}
                          </div>
                          <div class="col-4 d-flex justify-content-center">
                            {{ reg.valor }}%
                          </div>
                          <div class="col-4 d-flex justify-content-center">
                            {{ reg.valorSinCalibrar }}%
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="d-flex justify-content-center align-items-center">
                    <i
                      class="bi bi-exclamation-diamond-fill text-warning fs-1 me-3"
                    ></i>
                    <span class="fs-2 text-muted">No hay información</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");
import { onMounted, reactive, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { verDispositivosId } from "@/services/dispositivos";
import * as echarts from "echarts";
export default {
  name: "ModalHistorial",
  setup() {
    const tipoGrafica = ref("Temperatura");
    const dataGeneral = ref([]);
    const dataTemp = ref({});
    const dataHume = ref({});
    const mostrarTempera = ref(false);
    const mostrarHumedad = ref(false);
    const fecha = ref(dayjs(dayjs().format("YYYY-MM-DD")).$d);
    const humGrafica = ref(null);
    let chartHume = reactive({});
    const route = useRoute();
    const fechaActual = ref(dayjs(fecha.value).format("DD/MM/YYYY"));
    const changeTipoGrafica = (tipo) => {
      tipoGrafica.value = tipo;
      if (dataHume.value[0].registros.length > 0) {
        setTimeout(() => {
          initChart();
        }, 500);
      }
    };

    const TextoTermo = (tipo) => {
      switch (tipo) {
        case 1:
          return "text-green";
        case 2:
          return "text-orange";
        case 3:
          return "text-blue";
        default:
          return "";
      }
    };
    const formatFecha = (fecha) => {
      return dayjs(fecha).format("hh:mm a");
    };
    const formatFecha2 = (fecha) => {
      return dayjs(fecha).format("DD MMM HH:mm ");
    };
    const fetchData = async () => {
      const { id } = route.params;
      await verDispositivosId(id, {
        periodo: 1,
        periodoFecha: dayjs(fecha.value).format("YYYY-MM-DD"),
        // periodoFecha: "2022-01-01",
      })
        .then(({ data }) => {
          dataGeneral.value = data;
          dataTemp.value = dataGeneral.value.sensores.filter(
            (d) => d.tipo === 1 || d.tipo === 2
          );
          dataHume.value = dataGeneral.value.sensores.filter(
            (d) => d.tipo === 3
          );
          if (
            dataTemp.value[0].registros.length > 0 &&
            dataTemp.value[1].registros.length > 0
          ) {
            mostrarTempera.value = true;
          }
          if (dataHume.value[0].registros.length > 0) {
            mostrarHumedad.value = true;
          }
        })
        .catch((err) => {
          console.error(err);
        });
    };
    const initChart = () => {
      if (tipoGrafica.value === "Humedad") {
        if (
          chartHume !== null &&
          chartHume !== "" &&
          chartHume !== undefined &&
          Object.keys(chartHume).length > 0
        ) {
          chartHume.dispose(); // borra la instancia en caso de estar creada
        }
        chartHume = echarts.init(humGrafica.value, "light");
        const gaugeData = [
          {
            value: dataHume.value[0].registroValor,
            name: "Actual",
            title: {
              offsetCenter: ["0%", "-25%"],
            },
            detail: {
              valueAnimation: true,
              offsetCenter: ["0%", "15%"],
            },
          },
        ];
        let option = {
          series: [
            {
              type: "gauge",
              startAngle: -90,
              endAngle: 270,
              pointer: {
                show: false,
              },
              progress: {
                show: true,
                overlap: false,
                roundCap: true,
                clip: false,
                itemStyle: {
                  color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
                    { offset: 0.6, color: "#335ce2" },
                    { offset: 0.3, color: "#13C8DC" },
                    { offset: 0.01, color: "#ffff" },
                  ]),
                },
              },
              axisLine: {
                lineStyle: {
                  width: 30,
                },
              },
              splitLine: {
                show: false,
                distance: 0,
                length: 10,
              },
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: false,
                distance: 100,
              },
              data: gaugeData,
              title: {
                fontSize: window.innerWidth < 768 ? 25 : 35,
                color: "#335ce2",
              },
              detail: {
                width: 50,
                height: 14,
                fontSize: window.innerWidth < 768 ? 40 : 50,
                color: "#335ce2",
                formatter: "{value}%",
              },
            },
          ],
        };

        chartHume.setOption(option, true);
      }
    };

    watch(
      () => fecha.value,
      async () => {
        fechaActual.value = dayjs(fecha.value).format("DD/MM/YYYY");
        await fetchData();
        if (dataHume.value[0].registros.length > 0) {
          initChart();
        }
      }
    );
    onMounted(async () => {
      await fetchData();
      window.addEventListener("resize", () => {
        if (dataHume.value[0].registros.length > 0) {
          initChart();
        }
      });
    });
    return {
      tipoGrafica,
      dataGeneral,
      dataTemp,
      dataHume,
      fechaActual,
      chartHume,
      humGrafica,
      mostrarHumedad,
      mostrarTempera,
      fecha,
      changeTipoGrafica,
      TextoTermo,
      formatFecha,
      formatFecha2,
      initChart,
    };
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 900px;
}
.modal-content {
  border-radius: 1rem;
}
.modal-header {
  border-radius: 1rem;
  padding: 1rem 1rem;
  background-image: url("/img/fondo-header-histo.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: none;
}
.caja-botones {
  background: #cbcbcb;
  border-radius: 40px;
  box-shadow: inset 0px 4px 3px 0px rgb(0 0 0 / 25%);
}
.b-tipo {
  border-radius: 40px;
}
.texto-valor {
  font-size: 25px;
}

.tabla-fondo::-webkit-scrollbar-track {
  background: #ffffff;
}

.tabla-fondo::-webkit-scrollbar-thumb {
  background-color: #dd4b39;
  border-radius: 10px;
  border: 3px solid #767676;
}
.tabla-fondo {
  overflow-x: hidden;
  overflow-y: auto;
  background: #e7e7e7;
  border-radius: 10px;
}
.icon-hum {
  font-size: 30px;
}
</style>
