<template>
  <rangoSensoresModal
    :datosDispo="dispositivo"
    :targetModal="`rangoSensoresModal_${dato.tipo}`"
    ref="rangoSensoresModal"
    @responseSuccess="$emit('responseSuccess')"
  />
  <div class="col-xxl-4 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
    <div class="card p-4" :class="cardFocus ? 'focusedCard' : ''">
      <div class="col-12 text-end pb-6">
        <span class="me-7">
          <el-tooltip
            class="box-item"
            effect="dark"
            content="Calibración"
            placement="top"
          >
            <img
              alt="Logo"
              src="/media/icons/calibra-icon.svg"
              style="width: 20px; height: 22px"
              @click="openModalCalibracion(dato.tipo)"
            />
          </el-tooltip>
        </span>
        <el-tooltip
          class="box-item"
          effect="dark"
          content="Configuración"
          placement="top"
        >
          <span
            @click="$refs.rangoSensoresModal.toggle(dato)"
            class="svg-icon svg-icon-4 me-3 cursor-pointer"
            data-bs-toggle="tooltip"
            data-bs-trigger="hover"
            :title="`Ajustar ${dato.nombre}`"
          >
            <i class="`bi bi-gear fs-3 text-blue" />
          </span>
        </el-tooltip>
      </div>
      <div class="d-flex flex-wrap border-bottom pb-4 justify-content-between">
        <div class="col-lg-6 col-md-6 col-sm-12 col-6">
          <div :class="`d-flex align-items-center ${TextoTermo(llave)}`">
            <div>
              <i
                v-if="dato.tipo === 3"
                :class="'bi bi-droplet-fill texto-valor ' + TextoTermo(llave)"
              ></i>
              <img
                v-else-if="dato.tipo === 2"
                src="@/assets/img/TermoNaranja.png"
                alt=""
                style="height: 70px"
              />
              <img
                v-else-if="dato.tipo === 1"
                src="@/assets/img/TermoVerde.png"
                alt=""
                style="height: 70px"
              />
            </div>
            <div>
              <span
                :class="
                  dato.tipo === 3 ? 'fs-3 ps-2 fw-bolder' : 'fs-3 fw-bolder'
                "
              >
                {{ dato.nombre }}
              </span>
            </div>
          </div>
        </div>
        <div
          class="col-lg-6 col-md-6 col-sm-12 col-6 text-end d-flex justify-content-end"
        >
          <div>
            <span
              ><p class="fs-6 text-muted text-center fw-bold mb-n4">
                {{ fecha_custom !== fechaActual ? fecha_custom : "Actual" }}
              </p></span
            >
            <span class="texto-valor fw-bold">
              {{ dato.registroValor }}{{ dato.tipo === 3 ? "%" : "°" }}
            </span>
          </div>
        </div>
      </div>
      <div class="d-flex border-bottom">
        <div class="col-6 border-end py-8">
          <div class="d-flex justify-content-center">
            <div class="pt-2 pe-3">
              <i class="bi bi-clock fs-1 custom-text" />
            </div>
            <div class="fs-6 text-center text-muted">
              <p class="fw-bolder">Intervalo</p>
              <p class="fw-light">{{ dato.intervalo }} minutos</p>
            </div>
          </div>
        </div>
        <div class="col-6 py-8">
          <div class="d-flex justify-content-center">
            <div class="pt-2 pe-3">
              <i class="bi bi-bar-chart-fill fs-1 custom-text" />
            </div>
            <div class="fs-6 text-center text-muted">
              <p class="fw-bolder">Rango</p>
              <p class="fw-light">
                {{ dato.rangoMinimo }} a {{ dato.rangoMaximo }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 d-flex justify-content-center pt-5">
        <span class="text-muted" v-if="dato.registros.length > 0">
          Ultimo registro {{ formatFecha(dato.registros[0].fecha) }}</span
        >
        <span v-else class="text-muted"> No hay registros de este sensor </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import dayjs from "dayjs";
import "dayjs/locale/es";
dayjs.locale("es");
import rangoSensoresModal from "@/components/modals/device/RangoSensoresModal.vue";

export default {
  props: {
    dato: Object,
    llave: Number,
    cardFocus: Boolean,
    dispositivo: Object,
    fecha_custom: String,
  },
  emits: ["responseSuccess", "openModalCalibracion"],
  components: {
    rangoSensoresModal,
  },
  setup(props, { emit }) {
    const fechaActual = ref(dayjs().format("YYYY-MM-DD"));
    const collapsed = ref(false);
    const TextoTermo = (tipo) => {
      switch (tipo) {
        case 0:
          return "text-green";
        case 1:
          return "text-orange";
        case 2:
          return "text-blue";
        default:
          return "";
      }
    };
    const formatFecha = (fecha) => {
      return dayjs(fecha).format("hh:mm a");
    };
    const openModalCalibracion = (tipo) => {
      let certi = "";
      switch (tipo) {
        case 1:
          //temperatura interna
          certi = "TAMB";
          break;
        case 2:
          //temperatura externa
          certi = "TLD";
          break;
        case 3:
          //humedad
          certi = "HR";
          break;
      }
      emit("openModalCalibracion", certi);
    };
    return {
      collapsed,
      fechaActual,
      TextoTermo,
      formatFecha,
      openModalCalibracion,
    };
  },
};
</script>
<style scoped>
.texto-valor {
  font-size: 40px;
}
.custom-text {
  color: #e3e3e3;
}
.focusedCard {
  box-shadow: inset -3px 1px 18px 0px rgb(56 71 109 / 8%);
}
</style>
