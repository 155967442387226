<template>
  <button
    class="btn bg-blue rounded-pill text-white ms-2"
    data-bs-toggle="modal"
    :data-bs-target="`#${targetModal}`"
    v-show="false"
    ref="btnShowModal"
  >
    Notificación
  </button>
  <div
    class="modal fade"
    :id="targetModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header flex-column flex-md-row">
          <div class="d-flex">
            <div class="pt-2 px-3">
              <i class="bi bi-bell fs-3 text-body"></i>
            </div>
            <div>
              <span class="fs-1 fw-bolder">Crear alerta</span>
            </div>
          </div>
          <div class="align-items-end">
            <img
              alt="Logo"
              src="/media/logos/logo_sens.svg"
              style="width: 30px"
            />
            <span class="fs-3 fw-bolder pe-2">{{ datosDispo.nombre }}</span>
            <span class="fs-4 text-muted fw-light">{{
              datosDispo.areaNombre
            }}</span>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
        </div>
        <div class="modal-body">
          <div class="row mb-4">
            <div class="col-md-12 d-flex justify-content-center">
              <span class="fs-1 fw-bolder">Recibir alertas</span>
            </div>
          </div>
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-12 pb-4">
                  <span class="" style="color: #848484; font-size: 22px">
                    Seleccione el medio que desea usar para recibir sus mensajes
                    de alerta
                  </span>
                </div>
                <div class="col-12">
                  <div class="form-check form-check-inline">
                    <input
                      @change="setDataTipoNotificacion(1)"
                      class="form-check-input"
                      type="checkbox"
                      id="checkEmail"
                      v-model="checkEmail"
                    />
                    <label class="form-check-label" for="checkEmail"
                      >Correo electrónico</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      @change="setDataTipoNotificacion(2)"
                      class="form-check-input"
                      type="checkbox"
                      id="checkSms"
                      v-model="checkSms"
                    />
                    <label class="form-check-label" for="checkSms"
                      >Número celular</label
                    >
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      @change="setDataTipoNotificacion(3)"
                      class="form-check-input"
                      type="checkbox"
                      id="checkNothing"
                      v-model="checkNothing"
                    />
                    <label class="form-check-label" for="checkNothing"
                      >No quiero recibir alertas</label
                    >
                  </div>
                </div>
                <div class="col-12 pb-4">
                  <span class="text-danger">{{ errors.tipoNotificacion }}</span>
                </div>
              </div>
              <div class="row pb-4">
                <div class="col-12">
                  <label for="destinatarios" class="fw-bolder"
                    >Destinatarios</label
                  >
                  <textarea
                    v-model="model.destinatarios"
                    class="form-control"
                    rows="8"
                    placeholder="Escriba el correo de los usuarios que recibirán mensajes de alerta"
                  >
                  </textarea>
                  <span class="text-danger">{{ errors.destinatarios }}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <div class="d-flex">
                    <label for="mensajes" class="fw-bolder">Mensaje</label>
                    <label for="mensajes">
                      <i
                        class="bi bi-circle-fill"
                        style="
                          color: #01579b;
                          font-size: 7px;
                          padding-left: 10px;
                        "
                      ></i>
                    </label>
                    <label
                      class="ml-4"
                      style="color: #848484; padding-left: 10px"
                    >
                      Personaliza tu mensaje de alerta, máximo 150 caracteres
                    </label>
                  </div>
                  <textarea
                    v-model="model.mensajes"
                    class="form-control"
                    rows="8"
                    placeholder="Escriba su mensaje de alerta"
                  >
                  </textarea>
                  <span class="text-danger">{{ errors.mensajes }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-5">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              ref="btnCloseModal"
              v-show="false"
            >
              Close
            </button>
            <div class="col-md-4 text-end">
              <button
                @click="onSubmit"
                name="button"
                class="btn bg-blue text-white btn-sm w-100"
              >
                Guardar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useRoute } from "vue-router";
import { ref, watch } from "vue";
import { verNotificacion, crearNotificacion } from "@/services/dispositivos";
import { alerta } from "@/utils/mensajes";

export default {
  name: "NotificationModal",
  props: {
    targetModal: {
      type: String,
      default: "notificationModal",
    },
    datosDispo: {
      type: Object,
    },
  },
  setup() {
    const route = useRoute();
    const btnShowModal = ref();
    const btnCloseModal = ref();
    const checkEmail = ref(false);
    const checkSms = ref(false);
    const checkNothing = ref(false);
    const model = ref({
      tipoNotificacion: 1,
      destinatarios: "",
      mensajes: "",
    });
    const errors = ref({
      tipoNotificacion: "",
      destinatarios: "",
      mensajes: "",
    });

    const resetForm = () => {
      model.value = {
        tipoNotificacion: "",
        destinatarios: "",
        mensajes: "",
      };
      errors.value = {
        tipoNotificacion: "",
        destinatarios: "",
        mensajes: "",
      };
    };

    const onSubmit = () => {
      let bandera = 0;
      if (!model.value.tipoNotificacion) {
        errors.value.tipoNotificacion = "medio notificación es requerido";
        bandera++;
      }
      if (!model.value.destinatarios) {
        errors.value.destinatarios = "destinatario es requerido";
        bandera++;
      }
      if (!model.value.mensajes) {
        errors.value.mensajes = "mensaje es requerido";
        bandera++;
      }
      if (bandera) return false;
      const { id } = route.params;
      crearNotificacion(id, model.value).then(({ data }) => {
        alerta(
          "Mensaje",
          data.message ? data.message : "creado correctamente",
          "success"
        );
        resetForm();
        btnCloseModal.value.click();
      });
    };
    const fetchNotificaciones = () => {
      const { id } = route.params;
      verNotificacion(id)
        .then(({ data }) => {
          resetForm();
          if (data) {
            model.value.tipoNotificacion = data.tipoNotificacion;
            model.value.destinatarios = data.destinatarios;
            model.value.mensajes = data.mensajes;
            getDataTipoNotificacion();
          }
          btnShowModal.value.click();
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const getDataTipoNotificacion = () => {
      checkEmail.value = model.value.tipoNotificacion === "1" ? true : false;
      checkSms.value = model.value.tipoNotificacion === "2" ? true : false;
      checkNothing.value = model.value.tipoNotificacion === "3" ? true : false;
    };

    const setDataTipoNotificacion = (val) => {
      model.value.tipoNotificacion = "";
      if (val === 1) {
        model.value.tipoNotificacion = "1";
      } else if (val === 2) {
        model.value.tipoNotificacion = "2";
      } else if (val === 3) {
        model.value.tipoNotificacion = "3";
      }
      getDataTipoNotificacion();
    };

    const toggle = () => {
      fetchNotificaciones();
    };

    watch(
      () => model.value.tipoNotificacion,
      (val) => {
        errors.value.tipoNotificacion = val
          ? ""
          : "medio notificación es requerido";
      }
    );
    watch(
      () => model.value.destinatarios,
      (val) => {
        errors.value.destinatarios = val ? "" : "destinatarios es requerido";
      }
    );
    watch(
      () => model.value.mensajes,
      (val) => {
        errors.value.mensajes = val ? "" : "mensajes es requerido";
      }
    );

    return {
      btnShowModal,
      btnCloseModal,
      model,
      errors,
      checkEmail,
      checkSms,
      checkNothing,
      onSubmit,
      toggle,
      setDataTipoNotificacion,
    };
  },
};
</script>

<style scoped>
.modal-dialog {
  max-width: 990px;
}
.modal-content {
  border-radius: 1rem;
}
.modal-header {
  border-radius: 1rem;
  padding: 1rem 1rem;
  background-image: url("/img/fondo-header-histo.png");
  background-repeat: no-repeat;
  background-size: cover;
  border-bottom: none;
}
.texto-paginator {
  padding-left: 15rem;
}
@media (max-width: 991px) {
  .card-body {
    padding: 1em 1em;
  }
  .texto-paginator {
    padding-left: 0rem;
  }
}
</style>
