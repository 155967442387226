
import { verDispositivosId } from "@/services/dispositivos";
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DispositivoGrafica from "@/components/widgets/graficas/dispositivoGrafica.vue";
import ModalCalibracion from "@/components/modals/device/CalibrationModal.vue";
import ModalNotifiacion from "@/components/modals/device/NotificationModal.vue";
import CardTemperatura from "./CardTemperatura.vue";
import ModalHistorial from "./ModalHistorial.vue";
import { useRoute } from "vue-router";
import dayjs from "dayjs";

export default defineComponent({
  name: "documents",
  components: {
    DispositivoGrafica,
    CardTemperatura,
    ModalCalibracion,
    ModalNotifiacion,
    ModalHistorial,
  },
  setup() {
    const dispositivo = ref([]);
    const dispoGrafica = ref();
    const modalHistorial = ref();
    const modalCalibracion = ref();
    const cardFocus = ref([]);
    const fechaCustomCard = ref(dayjs().format("YYYY-MM-DD"));
    onMounted(async () => {
      setCurrentPageBreadcrumbs("Documents", ["Pages", "Profile"]);
      await fetchDispositivos();
    });
    const fetchDispositivos = async () => {
      const { id } = route.params;
      await verDispositivosId(id, {
        periodo: 1,
        // periodoFecha: "2022-01-01",
        periodoFecha: fechaCustomCard.value,
      })
        .then(({ data }) => {
          dispositivo.value = data;
          cardFocus.value = dispoGrafica.value.focusCard;
        })
        .catch((err) => {
          console.log(err);
        });
    };
    const route = useRoute();
    const option = ref({});
    const changeFocusedCard = (array) => {
      cardFocus.value = array;
    };
    const refreshDataCards = async (fecha) => {
      console.log(fecha);
      if (fecha) {
        fechaCustomCard.value = dayjs(fecha).format("YYYY-MM-DD");
      } else {
        fechaCustomCard.value = dayjs().format("YYYY-MM-DD");
      }
      await fetchDispositivos();
    };
    const TextoTermo = (tipo) => {
      switch (tipo) {
        case 0:
          return "text-green";
        case 1:
          return "text-blue";
        case 2:
          return "text-orange";
        default:
          return "";
      }
    };

    const setDataSensor = () => {
      fetchDispositivos();
    };

    const openCalibracion = (tipo) => {
      modalCalibracion.value.toggle(tipo);
    };
    return {
      option,
      dispositivo,
      dispoGrafica,
      cardFocus,
      modalHistorial,
      modalCalibracion,
      fechaCustomCard,
      TextoTermo,
      setDataSensor,
      changeFocusedCard,
      openCalibracion,
      refreshDataCards,
    };
  },
});
